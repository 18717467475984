import React from "react";
import { FaUser } from "react-icons/fa";

const Heading = () => {
  return (
    <div className="flex justify-between px-20 py-20 w-[650px] items-center content-center">
        <div className="ml-[100px] flex  w-[650px] items-center content-center">
      <div>
        <FaUser className="text-[#D9D9D9] text-[40px]" />
      </div>
      <div className="ml-[10px]">
        <h2 style={{ color: "rgba(212, 245, 249, 1)" }} className="text-[12px]">WELCOME</h2>
        <h2 style={{ color: "rgba(212, 245, 249, 1)" }} className="text-[12px]">IBUKUN OLUWA</h2>
      </div>
      </div>
    </div>
  );
};

export default Heading;
