import React from "react";
import { Link } from "react-router-dom";

function Ourmission() {
  return (
    <div className="w-full h-auto px-6 py-10 md:h-[345px] md:px-24 md:py-20">
      <h2 className="text-blue-200 font-bold text-2xl md:text-4xl leading-tight">
        Our Mission <br />
      </h2>
      <p className="text-[#D4F5F9] text-sm md:text-base mt-4 md:mt-2">
      At Emerald, our mission is to empower our clients by providing exceptional investment opportunities that drive sustainable growth and long-term financial success. 
      </p>
      <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-8 rounded-[15px] place-items-center bg-[#0096C7] text-base md:text-lg font-medium text-[#00001A] px-4 py-4">
        <Link to="/signup" className="font-istok-web">
          Get Started
        </Link>
      </div>
    </div>
  );
}

export default Ourmission;
