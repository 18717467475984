import React, { useState, useEffect } from "react";
import Navbar from "../component/Dasboard/Navbar";
import Heading from "../component/Dasboard/Heading";

import { url } from "../api/api";
import axios from "axios";
import Jumbutron from "../component/Investment/Jumbutron";

const Investment = () => {
  const [balance, setBalance] = useState([]);

  const totalFinalBalance = balance.reduce((accumulator, transaction) => {
    return accumulator + transaction.finalBalance;
  }, 0);

  const formattedBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalFinalBalance);

  console.log(formattedBalance);

  useEffect(() => {
    // Fetch data from backend

    const userid = JSON.parse(localStorage.getItem("userdata"));

    axios
      .get(`${url}/funding/fetchbalance`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  return (
    <div className="md:h-[800px] bg-[#00001A]">
      <Navbar />
      <Heading />
      <Jumbutron totalbalance={formattedBalance} />
    </div>
  );
};

export default Investment;
