import React from "react";

export default function Activityindicator() {
  return (
    <button
      type="submit"
      className="bg-[#213f7a]  rounded-md px-6 py-3 text-green-500 font-semibold w-80 border border-white"
                  >
      Loading..
    </button>
  );
}