import React from "react";

const Index = () => {
  return (
    <div className="w-full h-auto bg-[#00001A] px-6 py-10 md:h-[500px] md:px-24 md:py-20">
   
      <h2 className="text-blue-200 font-[700] text-[32px] md:text-[52px] font-[istok] leading-tight mt-6">
        Emerald Capital <br /> Privacy Portal
      </h2>
      <p className="text-white text-sm md:text-base mt-4 md:mt-0">
        we are committed to protecting your data
        <br className="hidden md:inline" />
        <br className="hidden md:inline" />
        We understand how important your data is, we equally understand how
        concerened you are about how your data is proccessed, and stored.
        <br className="hidden md:inline" />
        <br className="hidden md:inline" />
        We created this page to help you navigate through key aspects of our
        Privacy Program and have total understanding of your privacy rights. At
        Emerald Capital, we are dedicated to safeguarding your privacy and
        protecting your data. The security of your personal information is
        paramount to us and we follow strict internal guidelines, legal
        requirements and industry best practices to ensure that your data is
        secure and used only for authorized purposes.
      </p>
    </div>
  );
};

export default Index;
