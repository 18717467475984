import React, {useState } from "react";
import axios from "axios";
import { url } from "../api/api";
import Mobileheader from "../component/Mobiledashboard/Mobileheader";
import Mobilenav from "../component/Mobiledashboard/Mobilenav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Activityindicator from "../Activityindicator";


const Passwordchange = () => {
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");



  const handleoldpasswordChange = (event) => {
    const oldpasswordchange = event.target.value;
    setOldPassword(oldpasswordchange);
  };

  const handlenewpasswordChange = (event) => {
    const newpasswordchange = event.target.value;
    setNewPassword(newpasswordchange);
  };

  const handlenewconfirmNewPasswordChange = (event) => {
    const newpasswordchange = event.target.value;
    setconfirmNewPassword(newpasswordchange);
  };

  
  const [isLoadingsubmit, setIsLoadingsubmit] = useState(false);


  const handleSaveClick = () => {

    setIsLoadingsubmit(true);

    const formData ={
        password:oldpassword,
        passwordnew:newpassword
    }


    if(newpassword === confirmNewPassword){


    const userid = JSON.parse(localStorage.getItem("userdata"));
    axios
      .post(`${url}/users/changepassword`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setIsLoadingsubmit(false);
       toast.success("Password Updated Successfully", {
        position:"top-center",
      });

      

      })
      .catch((error) => {
        setIsLoadingsubmit(false);
        console.error("Error updating user data:", error);
        toast.error("Error Changing Password", {
            position: "top-right",
          });
      });

    }else{

        toast.error("Password Don't Match", {
            position: "top-right",
          });
    

    }

  };


  return (
    <div className="h-[1000px] bg-[#00001A] px-6">
      <Mobileheader className="mt-[30px]"/>
      <div className="space-y-4 px-4 mt-[50px] gap-4 grid">
     
        <h2 className="text-[#D4F5F9] font-[900] text-[25px]">
          {" "}
          Change Password
        </h2>

        <ToastContainer />
        <label className="text-[#fff] font-[900] text-[17px]"> Enter Old Password</label>
        <input
          type="text"
          name="oldpassword"
          value={oldpassword}
          onChange={handleoldpasswordChange}
          className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
          required
        />
        <label className="text-[#fff] font-[900] text-[17px]"> Enter New Password</label>
        <input
          type="text"
          name="text"
          value={newpassword}
          onChange={handlenewpasswordChange}
          className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
        required
        />

     <label className="text-[#fff] font-[900] text-[17px]"> Confirm New Password</label>
        <input
          type="text"
          name="text"
          value={confirmNewPassword}
          onChange={handlenewconfirmNewPasswordChange}
          className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
     required
        />
{
    isLoadingsubmit ? <Activityindicator/> :   <button
    onClick={handleSaveClick}
    className="w-full p-2 bg-[#D4F5F9] rounded text-black"
  >
   Change Password
  </button>
}
      
      </div>
      <Mobilenav />
    </div>
  );
};

export default Passwordchange;
