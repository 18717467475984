import React, { useState } from "react";

const Mobiletransactionhist = ({  transactions }) => {
  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(transactions.map((transaction) => transaction.fundingtype)),
  ];

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesSearchTerm = transaction.fundingtype
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filter === "All" || transaction.fundingtype === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = filteredTransactions.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };
  return (
    <div className="grid w-full mt-16 px-4">
      <div className="w-full py-4">
        <h2 className=" text-[#D4F5F9] text-[20px] font-[900] font-[istok]">
          Transactions History
        </h2>

 
      </div>

      <div className="mb-4">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-2 border border-gray-300 rounded ml-2"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {fundingTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <table className="min-w-full mt-10">
          <thead className="bg-[#D4F5F9]">
            <tr>
              <th className="py-2 px-4 border-b">Funding Type</th>
              <th className="py-2 px-4 border-b">Final Balance</th>

              <th className="py-2 px-4 border-b">Type</th>
            </tr>
          </thead>
          <tbody className=" mt-6">
            {currentTransactions.map((transaction) => (
              <tr key={transaction.transactionid}>
                <td className="py-2 px-4 text-[#D4F5F9]">
                  {transaction.fundingtype}
                </td>
                <td
                  className={`py-2 px-4  ${
                    transaction.finalBalance < 0
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {transaction.finalBalance}
                </td>

                <td className="py-2 px-4  text-[#fff]">
                  {transaction.finalBalance < 0 ? "Debit" : "Credit"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            className="p-2 bg-gray-300 rounded"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="p-2 bg-gray-300 rounded"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Mobiletransactionhist;
