import React,  { useEffect, useState } from "react";
import Correct from "../img/Primary.png";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { url } from "../api/api";

const Plans = () => {


  const [investmentPlan, setinvestentPlan] = useState([]);

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/investment/fetchallinvestmentmethod`)
      .then((response) => response.json())
      .then((data) => setinvestentPlan(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="h-auto w-full bg-sky-500 pb-20 md:pb-[310px] md:mt-[-500px]">
      <div className="flex flex-col md:flex-row justify-between">
        <div></div>
        <div className="mt-8 md:mt-[120px] px-6 py-6 md:w-[50%]">
          <h3 className="text-[24px] md:text-[30px] text-white">
            <span className="text-sky-950">grow</span> your Investment
            Portfolio with plans created with
            <span className="text-sky-950"> YOU IN MIND</span>
          </h3>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between mt-10 md:mt-[230px] px-4 md:px-10 py-10 gap-10">
   


        {
  investmentPlan ? 
  investmentPlan.map((plan,index) => (
    <div key={plan._id} className={`h-auto md:h-[568px] px-4 py-4 md:px-10 md:py-10 rounded-lg
      ${index === 2 ? 'bg-gray-300 bg-opacity-30' : ''}
    `}>
      <div>
        <h2 className="text-amber-200 text-[16px] font-bold uppercase ">{plan.planname}</h2>
        <h2 className="text-blue-900 text-[28px] font-bold">${plan.from} - ${plan.to}</h2>
        <p className="text-amber-200 text-[10px] font-bold mt-4">
          Short term investments, with market watch protection
        </p>
        <div className="mt-6 flex">
          <img src={Correct} alt="" />
          <h3 className="text-white ml-4">Duration: {plan.duration} days</h3>
        </div>
        <div className="mt-6 flex">
          <img src={Correct} alt="" />
          <h3 className="text-white ml-4">
            <span className="text-amber-200 font-bold">ROI</span>: {plan.roi}%
          </h3>
        </div>
        <div className="mt-6 flex">
          <img src={Correct} alt="" />
          <h3 className="text-white ml-4">Market watch protection</h3>
        </div>
        <Link
          to="/signup" 
          className={`mt-10 flex border border-blue-950 h-[45px] rounded-lg justify-between items-center px-6 md:px-14    ${index === 0 ? 'bg-white' : ''}`}
        >
          <p>Get Started</p>
          <FaLongArrowAltRight color="black" size={30} />
        </Link>
      </div>
    </div>
  )) 
  : ""
}


  

    
      </div>
    </div>
  );
};

export default Plans;
