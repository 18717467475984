import React from "react";
import { Link } from "react-router-dom";

const Calltoaction = () => {
  return (
    <div className="h-auto w-full bg-slate-950 grid place-items-center py-10 md:h-[435px]">
      <h3 className="text-blue-200 text-[24px] md:text-[42px] font-[istok] font-[700] text-center">
        For Verified Users
      </h3>
      <h3 className="text-blue-200 text-[24px] md:text-[42px] font-[istok] font-[700] text-center mt-4 md:mt-0">
        Get up to <span className="text-orange-300">100 USDT</span> in rewards
      </h3>
      <Link to="/signup" className="w-[150px] md:w-[200px] flex mt-6 md:mt-[20px] bg-blue-100 bg-opacity-90 shadow-md h-[45px] rounded-lg justify-center items-center px-4 md:px-14">
        <p>Get Started</p>
      </Link>
    </div>
  );
};

export default Calltoaction;
