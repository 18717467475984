import React, { useEffect, useState } from "react";

import Mobileheader from "../component/Mobiledashboard/Mobileheader";
import Mobilejumbotron from "../component/Mobiledashboard/Mobilejumbotron";
import Mobilenav from "../component/Mobiledashboard/Mobilenav";
import axios from "axios";
import { url } from "../api/api";
import Mobileinvesttransact from "../component/Mobiledashboard/Mobileinvesttransact";

const Mobileinvestment = () => {
  const [investment, setInvestment] = useState([]);

  useEffect(() => {
    // Fetch data from backend

    const userid = JSON.parse(localStorage.getItem("userdata"));

    axios
      .get(`${url}/investment/fetchsingleinvestment/${userid._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setInvestment(response.data.investment);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);


  const [balance, setBalance] = useState([]);

  const totalFinalBalance = balance.reduce((accumulator, transaction) => {
    return accumulator + transaction.finalBalance;
  }, 0);

  const formattedBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalFinalBalance);

  console.log(formattedBalance);

  useEffect(() => {
    // Fetch data from backend

    const userid = JSON.parse(localStorage.getItem("userdata"));

    axios
      .get(`${url}/funding/fetchbalance`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  return (
    <div className="h-[1200px] bg-[#00001A] px-6 ">
      <Mobileheader />
      <Mobilejumbotron totalbalance={formattedBalance} />

      <Mobileinvesttransact investmented={investment} />
      <Mobilenav />
    </div>
  );
};

export default Mobileinvestment;
