import React from "react";

const Index = () => {
  return (
    <div className="w-full h-auto bg-[#00001A] px-6 py-10 md:h-[345px] md:px-24 md:py-20">
      <h2 className="text-blue-200 font-[700] text-[32px] md:text-[52px] font-[istok] leading-tight">
        Welcome to <br/> Emerald Capital
      </h2>
      <p className="text-white text-sm md:text-base mt-4 md:mt-0">
        At Emerald capital, we believe that everyone should have the freedom to
        <br className="hidden md:inline" />
        earn, hold, and invest their money in cryptocurrency without any hassle
        <br className="hidden md:inline" /> - no matter their level of understanding of cryptocurrency
      </p>
    </div>
  );
};

export default Index;
