import React from "react";
import { MdManageAccounts } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FcAbout } from "react-icons/fc";
import { MdContactPhone } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";

const Mobilesettingsbody = () => {


  return (
    <div className="grid gap-12  px-6 py-10 mt-[20px]">
      <Link to="/mobileuserprofile" className="flex gap-3 items-center w-[60%]">
        <MdManageAccounts className="text-[#D4F5F9] text-[35px]" />
        <h2 className="text-white text-[17px] font-[900]">User Profile</h2>
      </Link>

      <Link to="/passwordchange" className="flex gap-3 items-center w-full">
        <RiLockPasswordFill className="text-[#D4F5F9]  text-[35px]" />
        <h2 className="text-white text-[17px] font-[900]">Password Change</h2>
      </Link>

      <Link to="/mobileaboutus" className="flex  gap-3 items-center w-[60%]">
        <FcAbout className="text-[#D4F5F9]   text-[35px]" />
        <h2 className="text-white text-[17px] font-[900]">About Us</h2>
      </Link>
      <Link  to="/mobilecontactus" className="flex gap-3 items-center w-[60%]">
        <MdContactPhone className="text-[#D4F5F9]  text-[35px]" />
        <h2 className="text-white text-[17px] font-[900]">Contact Us</h2>
      </Link>
      <Link  to="/" className="flex gap-3 items-center w-[60%]">
        <FiLogOut className="text-[#D4F5F9]  text-[35px]" />
        <h2 className="text-white text-[17px] font-[900]">Logout</h2>
      </Link>
    </div>
  );
};

export default Mobilesettingsbody;
