import React from "react";
import Banner from "../../img/web2.png";

const Webimagefooter = () => {
  return (
    <div className="w-full mt-[-200px] hidden   md:block">
      <img src={Banner} alt=""  className=" h-[200px] w-[355px]" />
    </div>
  );
};

export default Webimagefooter;
