import React from "react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TiHome } from "react-icons/ti";
import { AiOutlineLogout } from "react-icons/ai";
import { Link } from "react-router-dom";

const Dashboardsidenav = () => {
  return (
    <div className="h-[400px] bg-[#000043] w-[118px] gap-20 grid place-content-center rounded-md">
      <div>
        <Link to="/dashboard">
          <TiHome className=" text-[#fff] text-[40px]" />
        </Link>
      </div>
      <div>
        <Link to="/transaction">
          <LiaFileInvoiceDollarSolid className=" text-[#fff] text-[40px]" />
        </Link>
      </div>
      <div>
        <Link to="/">
          <AiOutlineLogout className=" text-[#fff] text-[40px]" />
        </Link>
      </div>
    </div>
  );
};

export default Dashboardsidenav;
