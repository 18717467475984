import React from "react";
import Banner from "../img/web.png";

const Webimage = () => {
  return (
    <div className="w-full mt-[-300px] hidden md:block">
      <img src={Banner} alt="Banner" />
    </div>
  );
};

export default Webimage;
