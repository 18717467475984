import React from "react";
import Navbar from "../component/Navbar";
import Index from "../component/About";
import Ourmission from "../component/About/Ourmission";
import Footer from "../component/Footer";
import Webimagefooter from "../component/Webimagefooter";
import Outforte from "../component/About/Outforte";
import Services from "../component/About/Services";
import Webimgaeabout from "../component/About/Webimgaeabout";
import Putfirst from "../component/About/Putfirst";
import Founders from "../component/About/Founders";

const Aboutus = () => {
  return (
    <div className="w-full">
      <Navbar />
      <Index />
      <div className=" md:h-[1000px] bg-black w-full">
        <Ourmission />
        <Outforte />
        <Services />
        <Webimgaeabout />
      </div>
      <Putfirst />
      <Founders />
      <Footer />
      <Webimagefooter />
    </div>
  );
};

export default Aboutus;
