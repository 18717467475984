import React, { useState, useEffect } from "react";
import Navbar from "../component/Dasboard/Navbar";

import Jumbutron from "../component/Dasboard/Jumbutron";
import { url } from "../api/api";
import axios from "axios";

const Dashboard = () => {
  const [balance, setBalance] = useState([]);

  const totalFinalBalance = balance.reduce((accumulator, transaction) => {
    return accumulator + transaction.finalBalance;
  }, 0);

  const formattedBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalFinalBalance);

  console.log(formattedBalance);

  useEffect(() => {
    // Fetch data from backend

    const userid = JSON.parse(localStorage.getItem("adminuserdata"));

    axios
      .get(`${url}/funding/fetchbalance`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  return (
    <>
      <div className="md:h-screen bg-[#00001A] grid">
        <Navbar />
  
        <Jumbutron totalbalance={formattedBalance}  className="md:mt-[-300px]"/>
</div>
    </>
  );
};

export default Dashboard;
