import React, { useState } from "react";

const Mobileinvesttransact = ({ investmented }) => {
  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredInvestments = investmented.filter((investment) => {
    const matchesSearchTerm = investment.investmentransactionid
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filter === "All" ||
      (investment.status === 1 ? "Completed" : "Ongoing") === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredInvestments.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentInvestments = filteredInvestments.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  return (
    <div className="grid w-full py-6 px-2 gap-6 mt-10">
      <div className="grid  w-full">
        <h2 className=" text-[#fff] text-[30px] font-[900] font-[istok]">
          Investment History
        </h2>
      </div>

      <div className="mb-4">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded"
          placeholder="Search by Transaction ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-2 border border-gray-300 rounded ml-2"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Completed">Completed</option>
          <option value="Ongoing">Ongoing</option>
        </select>
      </div>
      <table className="min-w-full  ">
        <thead className="bg-[#D4F5F9] ">
          <tr className="text-black">
            <th className="py-2 px-4 border-b">Investment Amount</th>
            <th className="py-2 px-4 border-b">Duration (days)</th>

            <th className="py-2 px-4 border-b">Status</th>
          </tr>
        </thead>
        <tbody className="text-[#fff]">
          {currentInvestments.map((investment) => (
            <tr key={investment._id} className="text-[#fff]">
              <td className="py-2 px-4 border-b">
                {investment.investinvestmemntamount}
              </td>
              <td className="py-2 px-4 border-b">{investment.duration}</td>
              <td className="py-2 px-4 border-b">
                {investment.status === 1 ? "Completed" : "Ongoing"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <button
          className="p-2 bg-gray-300 rounded"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="p-2 bg-gray-300 rounded"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Mobileinvesttransact;
