import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../src/img/logo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Use the useNavigate hook

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem("adminuserdata");

    // Navigate to the login page
    navigate("/");
  };

  return (
    <div className="h-[80px] bg-[#00001A] flex px-4 justify-between items-center border-b border-b-white">
      <div className="mt-[-20px]">
        <img src={Logo} alt="logo" className="h-10 w-auto" />
      </div>
      <div className="hidden md:flex text-white justify-between gap-8">
        <Link
          to="/dashboard"
          className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
        >
          Home
        </Link>

        <button
          onClick={handleLogout}
          className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
        >
          Logout
        </button>
      </div>
      <div className="hidden md:flex text-white justify-between gap-2">
        <Link to="/profile" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>
        </Link>
      </div>
      <div className="md:hidden flex items-center">
        <button
          className="text-white focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-[80px] left-0 w-full bg-[#00001A] gap-6 flex flex-col items-center text-white pb-[20px]">
          <Link
            to="/dashboard"
            className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
          >
            Home
          </Link>

          <button
            onClick={handleLogout}
            className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
          >
            Logout
          </button>

          <Link to="/profile" className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
