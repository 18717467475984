import React from "react";

import Mobileheader from "../component/Mobiledashboard/Mobileheader";

import Mobilenav from "../component/Mobiledashboard/Mobilenav";
import Mobilesettingsbody from "../component/Mobiledashboard/Mobilesettingsbody";

const Mobilesettings = () => {
  return (
    <div className="h-screen bg-[#00001A] px-6 pt-[50px]">
      <Mobileheader />
      <Mobilesettingsbody/>
      <Mobilenav />
    </div>
  );
};

export default Mobilesettings;
