import React from "react";
import Navbar from "../component/Navbar";
import Jumbutron from "../component/Jumbutron";
import Webimage from "../component/Webimage";
import Plans from "../component/Plans";
import Calltoaction from "../component/Calltoaction";
import Blog from "../component/Blog";
import Footer from "../component/Footer";
import Webimagefooter from "../component/Webimagefooter";

function Home() {
  return (
    <div className="w-full">
      <Navbar />
      <Jumbutron />
      <Webimage />
      <Plans />
      <Calltoaction />
      <Blog />
      <Footer />
      <Webimagefooter/>
    </div>
  );
}

export default Home;
