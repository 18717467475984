import React from "react";
import Blogimg from "../img/blog.png";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <div className="h-auto w-full px-6 py-10 md:px-24 md:py-24">
      <h2 className="font-[700] font-[istok] text-[32px] md:text-[42px] text-sky-600 text-center md:text-left">Blog</h2>

      <div className="flex flex-col md:flex-row justify-between gap-4 mt-10 md:mt-14">
        <img src={Blogimg} alt="" className="w-full md:w-1/2" />
        <div className="mt-6 md:mt-0 md:w-1/2">
          <h2 className="text-[28px] md:text-[42px] text-orange-300 font-[700] font-[istok] text-center md:text-left">
            WHY INVEST WITH EMERALD
          </h2>
          <p className="text-justify mt-4 md:mt-6">
          At the core of Emerald's success is its team of seasoned financial experts who bring years of industry experience to the table. These professionals meticulously analyze market trends, ensuring that every investment decision is backed by robust research and data-driven insights. Their expertise enables Emerald to navigate the complexities of the financial markets, maximizing returns while minimizing risks.
          </p>
          <Link className="text-orange-300 block mt-4 md:mt-6 text-center md:text-left">View More</Link>
        </div>
      </div>
    </div>
  );
};

export default Blog;
