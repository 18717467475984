import React from "react";
import Handshake from "../../img/handshake.png";
import Frame from "../../img/Frame.png";

function Services() {
  return (
    <div className="w-full h-auto px-6 py-10 md:px-24 md:py-20 grid md:grid-cols-3 gap-12 md:mt-[-80px] grid-cols-1">
      <div className="bg-[#23292F] p-6 rounded-lg shadow-lg">
        <img src={Handshake} alt="" className=" ml-[-50px;]" />

        <h3 className="text-blue-200 font-bold text-2xl md:text-3xl mb-4">
          Emerald Exchange
        </h3>
        <p className="text-gray-300 text-sm md:text-base font-[istok]">
          Whether it’s for gas fees, Investment purposes, for farming or trading
          purposes, we offer a swift crypto exchange service.
        </p>
      </div>
      <div className="bg-[#23292F] p-6 rounded-lg shadow-lg font-[istok]">
        <h3 className="text-blue-200 font-bold text-2xl md:text-3xl mb-4 md:mt-[80px]">
          Market Watch
        </h3>
        <p className="text-gray-300 text-sm md:text-base">
          Understanding the Cryptocurrency market could be a hassle. We lift the
          stress of keeping up with trends and market news. Leave it to us,
          we’ve got you covered.
        </p>
      </div>
      <div className="bg-[#23292F] p-6 rounded-lg shadow-lg font-[istok]">
        <img src={Frame} className="ml-[-50px;]"  alt=""/>
        <h3 className="text-blue-200 font-bold text-2xl md:text-3xl mb-4">
          Emerald Invest
        </h3>
        <p className="text-gray-300 text-sm md:text-base font-[istok]">
          Emerald Exchange is the largest crypto exchange by trade volume.
        </p>
      </div>
    </div>
  );
}

export default Services;
