import React, { useState } from "react";
import Navbar from "../component/Navbar";
import Webimagefooter from "../component/Login/Webimagefooter";
import { useNavigate } from "react-router-dom";
import Activityindicator from "../Activityindicator";
import { url } from "../api/api";
import axios from "axios";

const Updatepassword = () => {
  const userid = localStorage.getItem("useridkey");

  console.log(userid);

  const [formData, setFormData] = useState({
    password: "",
    cpassword: "",
  });

  const [isLoading, setisLoading] = useState(false);
  const [error, seterrorStatus] = useState("");
  const navigate = useNavigate();

  console.log(error);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);

    if (formData.password !== formData.cpassword) {
        setisLoading(false);
      seterrorStatus("Password and confirm password field don't match");
    } else {
      await axios
        .patch(`${url}/users/updatepassword/${userid}`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // Process the successful response here
          setisLoading(false);
          localStorage.clear("useridkey");

          navigate("/changepasssuccess");
        })
        .catch((error) => {
          // Handle error responses here
          if (error.response) {
            setisLoading(false);
            if (error.response.status === 404) {
              setisLoading(false);
              seterrorStatus(error.response.data);
            } else if (error.response.status === 422) {
              setisLoading(false);
              seterrorStatus(error.response.data);
            } else {
              setisLoading(false);
              seterrorStatus("Unknown error exist while submiting form");
            }
          } else {
            setisLoading(false);
            console.error("Error:", error.message);
          }
          setisLoading(false);
        });
    }
  };

  return (
    <div className="w-full">
      <Navbar />

      <div className=" w-full h-[800px]  md:h-[650px] grid place-items-center bg-[#00001A] z-50">
        <div className=" bg-[#0096C7] text-white rounded-lg shadow-lg p-8 max-w-sm w-full">
          <div className="px-4 py-4">
            <h1 className="text-3xl font-bold mb-4 text-[#213f7a] ">
              Change password
            </h1>
            <p className="pb-[20px]">Enter new password below</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 mt-[30px]">
            {error && <h2 className="text-red-500"> {error}</h2>}
            <div>
              <label
                htmlFor="email"
                className="block text-[12px] font-medium mb-1 text-black"
              >
                New Password
              </label>
              <input
                type="password"
                id="email"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px] "
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-[12px] font-medium mb-1 text-black"
              >
                Confirm New Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 bg-[#0096C7]  border border-black rounded-[10px]"
                name="cpassword"
                value={formData.cpassword}
                onChange={handleChange}
                required
              />
            </div>

            <br />

            <br />

            <div className=" mt-[146px]">
              {isLoading ? (
                <Activityindicator />
              ) : (
                <button
                  type="submit"
                  className="  font-[istok] w-full py-4 px-4 bg-white rounded-md  focus:outline-none focus:ring-2 focus:ring-black text-black"
                >
                  Update Password
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <Webimagefooter />
    </div>
  );
};

export default Updatepassword;
