import React from "react";
import Profilepage from "../component/Profile/Profilepage";
import Navbar from "../component/Dasboard/Navbar";


const Profile = () => {
  return (
    <div className="md:h-[1100px] h-screen bg-[#00001A]">
      <Navbar />

      <div className="px-[10px] py-[10px] flex gap-6 ">
        <Profilepage />
      </div>
    </div>
  );
};

export default Profile;
