import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../api/api";
import Navbar from "../component/Dasboard/Navbar";
import Dashboardsidenav from "../component/Dasboard/Dashboardsidenav";

const UserTransactions = () => {
  const { userId } = useParams();
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/funding/fetchsingleusersbalance/${userId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTransactions(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  }, [userId]);

  const totalFinalBalance = transactions.reduce((accumulator, transaction) => {
    return accumulator + transaction.finalBalance;
  }, 0);

  const formattedBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalFinalBalance);

  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(transactions?.map((transaction) => transaction.fundingtype)),
  ];

  const filteredTransactions = transactions?.filter((transaction) => {
    const matchesSearchTerm = transaction.fundingtype
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter = filter === "All" || transaction.fundingtype === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-6 flex flex-col md:flex-row gap-6 mt-20">


        <div className="mb-4 w-full">
          <h2 className="text-white text-2xl mb-4">Available Balance: {formattedBalance}</h2>

          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full mt-4 text-center">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">TRANSACTION ID</th>
                  <th className="py-2 px-4 border-b">TRANSACTION TYPE</th>
                  <th className="py-2 px-4 border-b">AMOUNT</th>
                  <th className="py-2 px-4 border-b">TYPE</th>
                </tr>
              </thead>
              <tbody>
                {currentTransactions.map((transaction) => (
                  <tr key={transaction.transactionid} className="px-2 py-4">
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {transaction.transactionid}
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {transaction.fundingtype}
                    </td>
                    <td
                      className={`py-2 px-4 ${
                        transaction.finalBalance < 0 ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {transaction.finalBalance}
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {transaction.finalBalance < 0 ? "DEBIT" : "CREDIT"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400 w-full md:w-auto"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-white mt-2 md:mt-0">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400 w-full md:w-auto"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTransactions;
