import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screen/Home";
import Aboutus from "./screen/Aboutus";
import Privacy from "./screen/Privacy";
import Login from "./screen/Login";
import Signup from "./screen/Signup";
import Verifyemail from "./screen/Verifyemail";
import Dashboard from "./screen/Dashboard";
import Emailsuccess from "./screen/Emailsuccess";
import Forgotpass from "./screen/Forgotpass";
import Verifyforgotcode from "./screen/Verifyforgotcode";
import Updatepassword from "./screen/Updatepassword";
import Changepasssuccess from "./screen/Changepasssuccess";
import Profile from "./screen/Profile";
import Transaction from "./screen/Transaction";
import Investment from "./screen/Investment";
import Mobileinvestment from "./screen/Mobileinvestment";
import Mobiletransaction from "./screen/Mobiletransaction";
import Mobilesettings from "./screen/Mobilesettings";
import Mobileuserprofile from "./screen/Mobileuserprofile";
import Passwordchange from "./screen/Passwordchange";
import Mobileabouts from "./screen/Mobileabouts";
import Mobilecontactus from "./screen/Mobilecontactus";
import Contactus from "./screen/Contactus";
import Viewdeposit from "./screen/Viewdeposit";
import Viewinvestment from "./screen/Viewinvestment";
import Viewusers from "./screen/Viewusers";
import UserTransactions from "./screen/UserTransactions";
import Viewwithdrawals from "./screen/Viewwithdrawals";
import Viewwithdrawalmethods from "./screen/Viewwithdrawalmethods";
import Viewdepositmethods from "./screen/Viewdepositmethods";
import Viewinvestmentdepositmethod from "./screen/Viewinvestmentdepositmethod";
import ProtectedRoute from "./ProtectedRoute";  // Import the ProtectedRoute component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="/" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="verifyemail" element={<Verifyemail />} />
        <Route path="emailsuccess" element={<Emailsuccess />} />
        <Route path="forgotpass" element={<Forgotpass />} />
        <Route path="verifyforgotcode" element={<Verifyforgotcode />} />
        <Route path="updatepass" element={<Updatepassword />} />
        <Route path="changepasssuccess" element={<Changepasssuccess />} />
        
        {/* Protected Routes */}
        <Route path="dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="profile" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="transaction" element={<ProtectedRoute element={<Transaction />} />} />
        <Route path="investment" element={<ProtectedRoute element={<Investment />} />} />
        <Route path="mobileinvestment" element={<ProtectedRoute element={<Mobileinvestment />} />} />
        <Route path="mobiletransaction" element={<ProtectedRoute element={<Mobiletransaction />} />} />
        <Route path="mobilesettings" element={<ProtectedRoute element={<Mobilesettings />} />} />
        <Route path="mobileuserprofile" element={<ProtectedRoute element={<Mobileuserprofile />} />} />
        <Route path="passwordchange" element={<ProtectedRoute element={<Passwordchange />} />} />
        <Route path="mobileaboutus" element={<ProtectedRoute element={<Mobileabouts />} />} />
        <Route path="mobilecontactus" element={<ProtectedRoute element={<Mobilecontactus />} />} />
        <Route path="contactus" element={<ProtectedRoute element={<Contactus />} />} />
        <Route path="viewdeposits" element={<ProtectedRoute element={<Viewdeposit />} />} />
        <Route path="viewinvestment" element={<ProtectedRoute element={<Viewinvestment />} />} />
        <Route path="viewusers" element={<ProtectedRoute element={<Viewusers />} />} />
        <Route path="/user/:userId/transactions" element={<ProtectedRoute element={<UserTransactions />} />} />
        <Route path="viewwithdrawals" element={<ProtectedRoute element={<Viewwithdrawals />} />} />
        <Route path="viewwithdrawalsmethods" element={<ProtectedRoute element={<Viewwithdrawalmethods />} />} />
        <Route path="viewdepositmethods" element={<ProtectedRoute element={<Viewdepositmethods />} />} />
        <Route path="viewinvestmentmethod" element={<ProtectedRoute element={<Viewinvestmentdepositmethod />} />} />
      </Routes>
    </Router>
  );
}

export default App;
