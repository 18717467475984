import React, { useState, useEffect } from "react";
import Dashboardsidenav from "./Dashboardsidenav";

import { url } from "../../api/api";
import { Link } from "react-router-dom";

const Jumbutron = ({ totalbalance }) => {
  const [withdrawal, setWithdrawalMethod] = useState([]);

  const [paymentPlan, setPaymentPlan] = useState("");

  // const [error, seterrorStatus] = useState("");

  console.log(paymentPlan);

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/withdrawal/fetchallwithdrawal`)
      .then((response) => response.json())
      .then((data) => setWithdrawalMethod(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className=" md:py-4  py-[50px] grid gap-6  md:mt-[-20px]  mt-24 w-full">

      <div className="w-full  grid md:grid-cols-3 grid-cols-1 gap-6 place-content-center place-items-center">
        <Link to="/viewdeposits" className="bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900] ">VIEW DEPOSIT</h2>
        </Link>

        <Link to="/viewinvestment" className="bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900] ">VIEW INVESTMENT</h2>
        </Link>
        <Link to="/viewusers" className="bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900] ">VIEW USERS</h2>
        </Link>
        <Link to="/viewwithdrawals" className="bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900] ">WITHDRAWALS</h2>
        </Link>

        <Link to="/viewwithdrawalsmethods" className="bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900]  text-center">WITHDRAWALS METHODS</h2>
        </Link>

        <Link to="/viewdepositmethods" className="bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900]  text-center">DEPOSIT METHODS</h2>
        </Link>


        <Link to="/viewinvestmentmethod" className="px-4 py-4 bg-white cursor-pointer shadow-md border-black border-2 rounded-md h-[170px] w-[250px]  grid place-content-center place-items-center">
          <h2 className=" text-[20px] font-[900]  text-center">INVESTMENT METHODS</h2>
        </Link>



      </div>
    </div>
  );
};

export default Jumbutron;
