import React, { useEffect, useState } from "react";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { AiOutlineTransaction } from "react-icons/ai";

import { url } from "../../api/api";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import Activityindicator from "../../Activityindicator";
import Success from "../../img/success.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Mobilejumbotron = ({ totalbalance }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen6, setIsPopupOpen6] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleClosePopup1 = () => {
    setIsPopupOpen1(false);
  };

  const handleOpenPopup3 = () => {
    setIsPopupOpen3(true);
  };

  const handleClosePopup3 = () => {
    setIsPopupOpen3(false);
  };

  const handleClosePopup4 = () => {
    setIsPopupOpen4(false);
  };

  const handleOpenPopup5 = () => {
    setIsPopupOpen5(true);
  };

  const handleClosePopup5 = () => {
    setIsPopupOpen5(false);
  };

  const handleClosePopup6 = () => {
    setIsPopupOpen6(false);
  };

  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [investments, setInvestments] = useState([]);
  const [withdrawal, setWithdrawalMethod] = useState([]);

  const [selectedWithdrwal, setSelectedWithdrawal] = useState("");
  const [withdrawalPlan, setWithdrawalplan] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [paymentPlan, setPaymentPlan] = useState("");
  const [paymentDuration, setPlanduration] = useState("");
  const [investmentRoi, setInvestmentRoi] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [investmentAmount, setinvestmentAmount] = useState("");
  const [withdrawalamount, setWithdrawalamount] = useState("");
  const [withdrawalAdress, setWithdrawalAddress] = useState("");

  // const [error, seterrorStatus] = useState("");

  console.log(paymentPlan);

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/withdrawal/fetchallwithdrawal`)
      .then((response) => response.json())
      .then((data) => setWithdrawalMethod(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleWithdrawalChange = (event) => {
    const selectedWithdrawalName = event.target.value;
    setSelectedWithdrawal(selectedWithdrawalName);
    console.log(selectedWithdrawalName);
    const withdraw = withdrawal.find((w) => w._id === selectedWithdrawalName);
    setWithdrawalplan(withdraw ? true : false);
  };

  const handleWithdrawalamountChange = (event) => {
    const Withdrawaldamount = event.target.value;
    setWithdrawalamount(Withdrawaldamount);
  };
  const handleWithdrawalAddressChange = (event) => {
    const Withdrawaladdress = event.target.value;
    setWithdrawalAddress(Withdrawaladdress);
  };

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/investment/fetchallinvestmentmethod`)
      .then((response) => response.json())
      .then((data) => setInvestments(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleInvestmentChange = (event) => {
    const selectedInvestmentName = event.target.value;
    setSelectedInvestment(selectedInvestmentName);
    console.log(selectedInvestmentName);
    const investment = investments.find(
      (plan) => plan._id === selectedInvestmentName
    );

    setPaymentPlan(
      investment ? investment.from + "-" + investment.to + "USD" : ""
    );
    setPlanduration(investment ? investment.duration + "DAYS" : "");
    setInvestmentRoi(investment ? investment.roi + "%" : "");
  };

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/funding/fetchpaymentmethod`)
      .then((response) => response.json())
      .then((data) => setPayments(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handlePaymentChange = (event) => {
    const selectedPaymentName = event.target.value;
    setSelectedPayment(selectedPaymentName);
    const payment = payments.find((p) => p.paymentname === selectedPaymentName);
    setPaymentAddress(payment ? payment.paymentaddress : "");
  };

  const handleDepositChange = (event) => {
    setDepositAmount(event.target.value);
  };

  const handleInvestmentDepositChange = (event) => {
    setinvestmentAmount(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Construct the data to be sent to the backend
    const submissionData = {
      fundingtypename: selectedPayment,
      amount: depositAmount,
    };

    // Send data to backend

    const userid = JSON.parse(localStorage.getItem("userdata"));

    axios
      .post(`${url}/funding/addbalance`, submissionData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setIsPopupOpen1(true);
        setIsPopupOpen(false);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      });
  };

  const handleSubmitinvestment = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const userid = JSON.parse(localStorage.getItem("userdata"));
    // Construct the data to be sent to the backend
    const submissionData = {
      userid: userid._id,
      planid: selectedInvestment,
      investinvestmemntamount: investmentAmount,
    };

    // Send data to backend

    axios
      .post(`${url}/investment/makeinvestment`, submissionData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setIsPopupOpen4(true);
        setIsPopupOpen3(false);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          toast.error("You Have Insufficient Fund", {
            position: "top-right",
          });
          setIsLoading(false);
        } else {
          toast.error("Error Making Investment", {
            position: "top-right",
          });

          setIsLoading(false);
        }

        setIsLoading(false);
      });
  };

  const handleSubmitwithdrawal = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const userid = JSON.parse(localStorage.getItem("userdata"));
    // Construct the data to be sent to the backend
    const submissionData = {
      userid: userid._id,
      withdrawalid: selectedWithdrwal,
      withdrawalamount: withdrawalamount,
      withdrawaladdress: withdrawalAdress,
    };

    // Send data to backend

    axios
      .post(`${url}/withdrawal/makenewwithdrawal`, submissionData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setIsPopupOpen6(true);
        setIsPopupOpen5(false);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          toast.error("You Have Insufficient Fund", {
            position: "top-right",
          });
          setIsLoading(false);
        } else {
          toast.error("Error Making Investment", {
            position: "top-right",
          });

          setIsLoading(false);
        }

        setIsLoading(false);
      });
  };

  return (
    <div className="h-[200px] w-full px-3 py-6 ">
      <div className=" bg-transparent border border-white/30 rounded-md shadow-md px-6 py-6">
        <h2 className="text-[#D4F5F9]">TOTAL BALANCE</h2>
        <h2 className="text-customText1 mt-4"> {totalbalance}</h2>

        <div className="flex justify-between items-center content-center mt-[30px]">
          <div
            onClick={handleOpenPopup}
            className="rounded-[10px] cursor-pointer hover:text-black gap-2  hover:bg-white text-[#D4F5F9] px-2 py-2 grid place-items-center place-content-center w-[30%] border border-[#D4F5F9]"
          >
            <FaRegArrowAltCircleUp />
            <h2 className="text-[14px] ">Fund</h2>
          </div>
          <div
            onClick={handleOpenPopup3}
            className="rounded-[10px]  hover:text-black  cursor-pointer   gap-2 hover:bg-white text-[#D4F5F9] px-2 py-2 grid place-items-center place-content-center w-[30%] border border-[#D4F5F9]"
          >
            <AiOutlineTransaction />
            <h2 className="text-[14px]">Invest</h2>
          </div>
          <div
            onClick={handleOpenPopup5}
            className="rounded-[10px]  hover:text-black   cursor-pointer gap-2 hover:bg-white text-[#D4F5F9] px-2 py-2 grid place-items-center place-content-center w-[30%] border border-[#D4F5F9]"
          >
            <FaRegArrowAltCircleDown />
            <h2 className="text-[14px]">Withdraw</h2>
          </div>
        </div>
      </div>

      <main className="p-4">
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#23292F] p-6 rounded shadow-lg relative md:w-[443px] w-[340px]">
              <button
                onClick={handleClosePopup}
                className="absolute top-3 right-3 text-[#D4F5F9] hover:text-white focus:outline-none"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok]">
                Fund Wallet
              </h2>
              <form
                className="bg-[#23292F] shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="paymentName"
                  >
                    Payment Name
                  </label>
                  <select
                    id="paymentName"
                    value={selectedPayment}
                    onChange={handlePaymentChange}
                    className="shadow-md bg-[#414C56] appearance-none rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="" className="text-[#D4F5F9]">
                      Select Payment
                    </option>
                    {payments.map((payment) => (
                      <option key={payment._id} value={payment.paymentname}>
                        {payment.paymentname}
                      </option>
                    ))}
                  </select>
                </div>
                {paymentAddress && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      Payment Address
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={paymentAddress}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="depositAmount"
                  >
                    Deposit Amount
                  </label>
                  <input
                    id="depositAmount"
                    type="number"
                    value={depositAmount}
                    onChange={handleDepositChange}
                    className="shadow appearance-none bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div className="grid place-items-center place-content-center mt-[20px]">
                  {isLoading ? (
                    <Activityindicator />
                  ) : (
                    <button
                      type="submit"
                      className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
                    >
                      Submit Funding Request
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen1 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="grid place-items-center place-content-center bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <img src={Success} alt="" />
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok] mt-[60px]">
                Payment made successfully, assets will be added to your balance
                once confirmed
              </h2>
              <button
                onClick={handleClosePopup1}
                className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen3 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#23292F] p-6 rounded shadow-lg relative md:w-[443px] w-[340px]">
              <button
                onClick={handleClosePopup3}
                className="absolute top-3 right-3 text-[#D4F5F9] hover:text-white focus:outline-none"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok]">
                Make investment
              </h2>
              <ToastContainer />
              <form
                className="bg-[#23292F] shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmitinvestment}
              >
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="paymentName"
                  >
                    Investment Name
                  </label>
                  <select
                    id="paymentName"
                    value={selectedInvestment}
                    onChange={handleInvestmentChange}
                    className="shadow-md bg-[#414C56] appearance-none rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="" className="text-[#D4F5F9]">
                      Select Payment
                    </option>
                    {investments.map((investment) => (
                      <option key={investment._id} value={investment._id}>
                        {investment.planname}
                      </option>
                    ))}
                  </select>
                </div>
                {paymentPlan && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      Investment Plan
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={paymentPlan}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                {paymentDuration && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      Investment Duration
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={paymentDuration}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                {investmentRoi && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      RETURN ON INVESTMENT
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={investmentRoi}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="depositAmount"
                  >
                    Investment Amount
                  </label>
                  <input
                    id="depositAmount"
                    type="number"
                    value={investmentAmount}
                    onChange={handleInvestmentDepositChange}
                    className="shadow appearance-none bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div className="grid place-items-center place-content-center mt-[20px]">
                  {isLoading ? (
                    <Activityindicator />
                  ) : (
                    <button
                      type="submit"
                      className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
                    >
                      Make Investment
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen4 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="grid place-items-center place-content-center bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <img src={Success} alt="" />
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok] mt-[60px]">
                Invest made successfully
              </h2>
              <button
                onClick={handleClosePopup4}
                className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen5 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#23292F] p-6 rounded shadow-lg relative md:w-[443px] w-[340px]">
              <button
                onClick={handleClosePopup5}
                className="absolute top-3 right-3 text-[#D4F5F9] hover:text-white focus:outline-none"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok]">
                Make New Withdrawal
              </h2>
              <ToastContainer />
              <form
                className="bg-[#23292F] shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmitwithdrawal}
              >
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="paymentName"
                  >
                    Withdrwal Method
                  </label>
                  <select
                    id="paymentName"
                    value={selectedWithdrwal}
                    onChange={handleWithdrawalChange}
                    className="shadow-md bg-[#414C56] appearance-none rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="" className="text-[#D4F5F9]">
                      Select Withdrawal Method
                    </option>
                    {withdrawal.map((withdraw) => (
                      <option key={withdraw._id} value={withdraw._id}>
                        {withdraw.methodname}
                      </option>
                    ))}
                  </select>
                </div>
                {withdrawalPlan && (
                  <>
                    <div className="mb-4">
                      <label
                        className="block text-[#D4F5F9] text-sm font-bold mb-2"
                        htmlFor="paymentAddress"
                      >
                        Enter Withdrawal Amount
                      </label>
                      <input
                        id="paymentAddress"
                        type="number"
                        value={withdrawalamount}
                        onChange={handleWithdrawalamountChange}
                        className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-[#D4F5F9] text-sm font-bold mb-2"
                        htmlFor="paymentAddress"
                      >
                        Withdrawal Address
                      </label>
                      <input
                        id="paymentAddress"
                        type="text"
                        value={withdrawalAdress}
                        onChange={handleWithdrawalAddressChange}
                        className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                        required
                      />
                    </div>
                  </>
                )}

                <div className="grid place-items-center place-content-center mt-[20px]">
                  {isLoading ? (
                    <Activityindicator />
                  ) : (
                    <button
                      type="submit"
                      className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
                    >
                      Make Withdrawal Request
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen6 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="grid place-items-center place-content-center bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <img src={Success} alt="" />
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok] mt-[60px]">
                Withdrawal Request Made successfully
              </h2>
              <button
                onClick={handleClosePopup6}
                className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Mobilejumbotron;
