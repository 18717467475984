import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import Activityindicator from "../../Activityindicator";
import { toast } from "react-toastify";
import { url } from "../../api/api";
import axios from "axios";
import Success from "../../img/success.png";

const Transactbody = ({ investmented }) => {
  console.log(investmented);
  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredInvestments = investmented.filter((investment) => {
    const matchesSearchTerm = investment.investmentransactionid
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filter === "All" ||
      (investment.status === 1 ? "Completed" : "Ongoing") === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredInvestments.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentInvestments = filteredInvestments.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedInvestment, setSelectedInvestment] = useState("");

  const [paymentPlan, setPaymentPlan] = useState("");
  const [paymentDuration, setPlanduration] = useState("");
  const [investmentRoi, setInvestmentRoi] = useState("");
  const [investments, setInvestments] = useState([]);
  const [investmentAmount, setinvestmentAmount] = useState("");

  const handleClosePopup3 = () => {
    setIsPopupOpen3(false);
  };

  const handleOpenPopup3 = () => {
    setIsPopupOpen3(true);
  };
  const handleClosePopup4 = () => {
    setIsPopupOpen4(false);
  };

  const handleInvestmentDepositChange = (event) => {
    setinvestmentAmount(event.target.value);
  };

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/investment/fetchallinvestmentmethod`)
      .then((response) => response.json())
      .then((data) => setInvestments(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSubmitinvestment = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const userid = JSON.parse(localStorage.getItem("userdata"));
    // Construct the data to be sent to the backend
    const submissionData = {
      userid: userid._id,
      planid: selectedInvestment,
      investinvestmemntamount: investmentAmount,
    };

    // Send data to backend

    axios
      .post(`${url}/investment/makeinvestment`, submissionData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setIsPopupOpen4(true);
        setIsPopupOpen3(false);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          toast.error("You Have Insufficient Fund", {
            position: "top-right",
          });
          setIsLoading(false);
        } else {
          toast.error("Error Making Investment", {
            position: "top-right",
          });

          setIsLoading(false);
        }

        setIsLoading(false);
      });
  };

  const handleInvestmentChange = (event) => {
    const selectedInvestmentName = event.target.value;
    setSelectedInvestment(selectedInvestmentName);
    console.log(selectedInvestmentName);
    const investment = investments.find(
      (plan) => plan._id === selectedInvestmentName
    );

    setPaymentPlan(
      investment ? investment.from + "-" + investment.to + "USD" : ""
    );
    setPlanduration(investment ? investment.duration + "DAYS" : "");
    setInvestmentRoi(investment ? investment.roi + "%" : "");
  };

  return (
    <div className="grid w-full">
      <div className="flex justify-between w-full px-12 py-4">
        <h2 className=" text-[#D4F5F9] text-[20px] font-[900] font-[istok]">
          Investment
        </h2>

        <div
          onClick={handleOpenPopup3}
          className=" text-white text-[20px] font-[900] font-[istok] rounded-[10px] shadow-lg border border-[#D4F5F9] px-6 py-2
        flex justify-between items-center content-center cursor-pointer
        "
        >
          <h2>Add Investment</h2>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded"
          placeholder="Search by Transaction ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-2 border border-gray-300 rounded ml-2"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Completed">Completed</option>
          <option value="Ongoing">Ongoing</option>
        </select>
      </div>
      <table className="min-w-full ">
        <thead className="bg-[#D4F5F9] ">
          <tr className="text-black">
            <th className="py-2 px-4 border-b">Transaction ID</th>
            <th className="py-2 px-4 border-b">Investment Amount</th>
            <th className="py-2 px-4 border-b">Duration (days)</th>
            <th className="py-2 px-4 border-b">ROI</th>
            <th className="py-2 px-4 border-b">Profit</th>
            <th className="py-2 px-4 border-b">Status</th>
          </tr>
        </thead>
        <tbody className="text-[#fff]">
          {currentInvestments.map((investment) => (
            <tr key={investment._id} className="text-[#fff]">
              <td className="py-2 px-4 border-b">
                {investment.investmentransactionid}
              </td>
              <td className="py-2 px-4 border-b">
                {investment.investinvestmemntamount}
              </td>
              <td className="py-2 px-4 border-b">{investment.duration}</td>
              <td className="py-2 px-4 border-b">{investment.roi}</td>
              <td className="py-2 px-4 border-b">{investment.profit}</td>
              <td className="py-2 px-4 border-b">
                {investment.status === 1 ? "Completed" : "Ongoing"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <button
          className="p-2 bg-gray-300 rounded"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="p-2 bg-gray-300 rounded"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <main className="p-4">
        {isPopupOpen3 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <button
                onClick={handleClosePopup3}
                className="absolute top-3 right-3 text-[#D4F5F9] hover:text-white focus:outline-none"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok]">
                Make investment
              </h2>
              <ToastContainer />
              <form
                className="bg-[#23292F] shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmitinvestment}
              >
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="paymentName"
                  >
                    Investment Name
                  </label>
                  <select
                    id="paymentName"
                    value={selectedInvestment}
                    onChange={handleInvestmentChange}
                    className="shadow-md bg-[#414C56] appearance-none rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="" className="text-[#D4F5F9]">
                      Select Payment
                    </option>
                    {investments.map((investment) => (
                      <option key={investment._id} value={investment._id}>
                        {investment.planname}
                      </option>
                    ))}
                  </select>
                </div>
                {paymentPlan && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      Investment Plan
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={paymentPlan}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                {paymentDuration && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      Investment Duration
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={paymentDuration}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                {investmentRoi && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] text-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      RETURN ON INVESTMENT
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={investmentRoi}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="depositAmount"
                  >
                    Investment Amount
                  </label>
                  <input
                    id="depositAmount"
                    type="number"
                    value={investmentAmount}
                    onChange={handleInvestmentDepositChange}
                    className="shadow appearance-none bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div className="grid place-items-center place-content-center mt-[20px]">
                  {isLoading ? (
                    <Activityindicator />
                  ) : (
                    <button
                      type="submit"
                      className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
                    >
                      Make Investment
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen4 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="grid place-items-center place-content-center bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <img src={Success} alt="" />
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok] mt-[60px]">
                Invest made successfully
              </h2>
              <button
                onClick={handleClosePopup4}
                className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Transactbody;
