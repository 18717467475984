import React from "react";
import Banner from "../../img/web.png";

const Webimageprivacy = () => {
  return (
    <div className="w-full mt-[-600px] hidden md:block z-50">
      <img src={Banner} alt="Banner" className=" h-[500px] w-[430px] "/>
    </div>
  );
};

export default Webimageprivacy;
