import React from "react";

import Mobileheader from "../component/Mobiledashboard/Mobileheader";
import Mobilenav from "../component/Mobiledashboard/Mobilenav";


const Mobileabouts = () => {


  return (
    <div className="h-[1000px] bg-[#00001A] px-6">
      <Mobileheader className="mt-[30px]"/>
      <div className="px-6 py-6 mt-10  grid gap-12">
        <h2 className="text-white text-[30px] font-[istok] font-[900]">About Us</h2>

        <h2 className="text-[#D4F5F9] text-[25px] font-[istok] font-[900]">Welcome to
        Emerald Capital</h2>

        <p className=" text-justify text-white">

        At Emerald capital, we believe that everyone should have the freedom to
earn, hold, and invest their money in cryptocurrency without any hassle
- no matter their level of understanding of cryptocurrency.
        </p>


      </div>
      <Mobilenav />
    </div>
  );
};

export default Mobileabouts;
