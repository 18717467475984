import React from "react";
import Banner from "../img/web2.png";

const Webimagefooter = () => {
  return (
    <div className="w-full mt-[-200px] z-50">
      <img src={Banner} alt=""  className=" h-[200px] w-[355px]" />
    </div>
  );
};

export default Webimagefooter;
