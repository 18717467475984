import React from "react";
import Navbar from "../component/Navbar";


import Footer from "../component/Footer";
import Webimagefooter from "../component/Webimagefooter";


const Contactus = () => {
  return (
    <div className="w-full bg-[#00001A]">
      <Navbar />
      <div className="px-6 py-6 mt-10  grid gap-12 md:w-[700px]">
        <h2 className="text-white text-[30px] font-[istok] font-[900]">Contact Us</h2>

        <h2 className="text-[#D4F5F9] text-[25px] font-[istok] font-[900] ">Do you have any pending issues regarding payment , withdrawal or investment ? Do not hesitate to reach out to us.</h2>

        <p className=" text-justify text-white">
         EMAIL: sales@emeraldcapitalhq.com
        </p>


        <p className=" text-justify text-white">
         OFFICE ADDRESS: PO BOX 200122 Great Britian Avenue UNITED KINGDOM.
        </p>


      </div>
      <Footer />
      <Webimagefooter />
    </div>
  );
};

export default Contactus;
