import React, { useState } from "react";
import Navbar from "../component/Navbar";
import Webimagefooter from "../component/Login/Webimagefooter";
import { useNavigate } from "react-router-dom";
import Activityindicator from "../Activityindicator";
import { url } from "../api/api";
import axios from "axios";

const Forgotpass = () => {
    const [formData, setFormData] = useState({
        email: "",
      });
    
      const [isLoading, setisLoading] = useState(false);
      const [error, seterrorStatus] = useState("");
      const navigate = useNavigate();
    
      console.log(error);
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setisLoading(true);

        await axios
          .post(`${url}/users/forgotpassword`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // Process the successful response here
            setisLoading(false);
            localStorage.setItem("useridkey", response.data.userid);
    
            navigate("/verifyforgotcode");
          })
          .catch((error) => {
            // Handle error responses here
            if (error.response) {
                setisLoading(false);
              if (error.response.status === 401) {
                seterrorStatus("Invalid email");
              } else if (error.response.status === 422) {
                seterrorStatus("Invalid email");
    
              } else {
                seterrorStatus("Unknown error exist while submiting form");
              }
            } else {
                setisLoading(false);
              console.error("Error:", error.message);
            }
          });
      };

  return (
    <div className="w-full">
      <Navbar />

      <div className=" w-full h-[800px]  md:h-[650px] grid place-items-center bg-[#00001A] z-50">
        <div className=" bg-[#0096C7] text-white rounded-lg shadow-lg p-8 max-w-sm w-full">
          <div className="px-4 py-4">
            <h1 className="text-3xl font-bold mb-4 text-[#213f7a] ">
              Reset your password{" "}
            </h1>
            <p className="pb-[20px]">
              We would send a verification <br />
              code to your email.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 mt-[30px]">
            {error && <h2 className="text-red-500"> {error}</h2>}
            <div>
              <label
                htmlFor="email"
                className="block text-[12px] font-medium mb-1 text-black"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px] "
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className=" mt-[146px]">
              {isLoading ? (
                <Activityindicator />
              ) : (
                <button
                  type="submit"
                  className="  font-[istok] w-full py-4 px-4 bg-white rounded-md  focus:outline-none focus:ring-2 focus:ring-black text-black"
                >
                  Verify
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <Webimagefooter />
    </div>
  );
};

export default Forgotpass;
