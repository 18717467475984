import React from "react";
import Logo from "../img/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-black w-full px-6 py-10 md:px-10 md:py-10 flex flex-col md:flex-row gap-6 justify-between">
      <div className="w-full md:w-1/4">
        <img src={Logo} alt="logo" className="h-10" />
        <h2 className="text-white text-lg mt-4 md:mt-10">
          Grow Your Portfolio, Secure Your Future
        </h2>
        <p className="text-sm text-white mt-4">
        Emerald Investments stands out as a premier choice for investors looking to grow their wealth through a reliable and forward-thinking platform. 
        </p>
      </div>
      <div className="w-full md:w-1/4">
        <h2 className="text-white text-lg mt-10 font-bold">Account</h2>
        <div className="mt-4 grid gap-4">
          <Link to="/signup" className="text-sm text-gray-300">Signup</Link>
          <Link to="/login" className="text-sm text-gray-300">Login</Link>
        </div>
      </div>
      <div className="w-full md:w-1/4">
        <h2 className="text-white text-lg mt-10 font-bold">Community</h2>
        <div className="mt-4 grid gap-4">
          <Link to="/" className="text-sm text-gray-300">Home</Link>
          <Link to="/privacy" className="text-sm text-gray-300">Privacy</Link>
        </div>
      </div>
      <div className="w-full md:w-1/4">
        <h2 className="text-white text-lg mt-10 font-bold">Contact</h2>
        <div className="mt-4 grid gap-4">
          <Link  to="/aboutus" className="text-sm text-gray-300">About Us</Link>
          <Link to="/contactus" className="text-sm text-gray-300">Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
