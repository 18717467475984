import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../api/api";
import Mobileheader from "../component/Mobiledashboard/Mobileheader";
import Mobilenav from "../component/Mobiledashboard/Mobilenav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Activityindicator from "../Activityindicator";


const Mobileuserprofile = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState();

  const handleEmailChange = (event) => {
    const emailchange = event.target.value;
    setEmail(emailchange);
  };

  const handleFullnameChange = (event) => {
    const fullnamechange = event.target.value;
    setFullname(fullnamechange);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingsubmit, setIsLoadingsubmit] = useState(false);

  useEffect(() => {
    const userid = JSON.parse(localStorage.getItem("userdata"));
    axios
      .get(`${url}/users/viewprofile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setFullname(response.data.fullname);
        setEmail(response.data.email);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error)
        toast.error("Error Fetching User Profile", {
            position: "top-right",
          });
       
        setIsLoading(false);
      });
  }, []);

  const handleSaveClick = () => {

    setIsLoadingsubmit(true);
    const formData ={
        fullname:fullname,
        email:email
    }
    const userid = JSON.parse(localStorage.getItem("userdata"));
    axios
      .post(`${url}/users/editprofile`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setIsLoadingsubmit(false);
       toast.success("Profile Updated Successfully", {
        position:"top-center",
      });

        setFullname(response.data.fullname);
        setEmail(response.data.email);

      })
      .catch((error) => {
        setIsLoadingsubmit(false);
        console.error("Error updating user data:", error);
        toast.error("Error Updating Profile", {
            position: "top-right",
          });
      });
  };

  if (isLoading) {
    return( <div className="h-screen grid place-content-center place-items-center bg-[#00001A] px-6 ">

        <Activityindicator/>
    </div>);
  }

  return (
    <div className="h-screen bg-[#00001A] px-6">
      <Mobileheader className="mt-[30px]"/>
      <div className="space-y-4 px-4 mt-[50px] gap-4 grid">
     
        <h2 className="text-[#D4F5F9] font-[900] text-[25px]">
          {" "}
          Profile Information
        </h2>

        <ToastContainer />
        <label className="text-[#fff] font-[900] text-[17px]"> Fullname</label>
        <input
          type="text"
          name="name"
          value={fullname}
          onChange={handleFullnameChange}
          className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
        />
        <label className="text-[#fff] font-[900] text-[17px]"> Email</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
          readOnly
        />

{
    isLoadingsubmit ? <Activityindicator/> :   <button
    onClick={handleSaveClick}
    className="w-full p-2 bg-[#D4F5F9] rounded text-black"
  >
    Update Profile
  </button>
}
      
      </div>
      <Mobilenav />
    </div>
  );
};

export default Mobileuserprofile;
