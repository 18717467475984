import React, { useState, useEffect} from "react";
import axios from "axios";
import { url } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Activityindicator from "../../Activityindicator";

const Profilepage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState();

  const handleEmailChange = (event) => {
    const emailchange = event.target.value;
    setEmail(emailchange);
  };

  const handleFullnameChange = (event) => {
    const fullnamechange = event.target.value;
    setFullname(fullnamechange);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingsubmit, setIsLoadingsubmit] = useState(false);

  useEffect(() => {
    const userid = JSON.parse(localStorage.getItem("adminuserdata"));
    axios
      .get(`${url}/users/viewprofile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setFullname(response.data.fullname);
        setEmail(response.data.email);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error)
        toast.error("Error Fetching User Profile", {
            position: "top-right",
          });
       
        setIsLoading(false);
      });
  }, []);

  const handleSaveClick = () => {

    setIsLoadingsubmit(true);
    const formData ={
        fullname:fullname,
        email:email
    }
    const userid = JSON.parse(localStorage.getItem("adminuserdata"));
    axios
      .post(`${url}/users/editprofile`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setIsLoadingsubmit(false);
       toast.success("Profile Updated Successfully", {
        position:"top-center",
      });

        setFullname(response.data.fullname);
        setEmail(response.data.email);

      })
      .catch((error) => {
        setIsLoadingsubmit(false);
        console.error("Error updating user data:", error);
        toast.error("Error Updating Profile", {
            position: "top-right",
          });
      });
  };


  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");



  const handleoldpasswordChange = (event) => {
    const oldpasswordchange = event.target.value;
    setOldPassword(oldpasswordchange);
  };

  const handlenewpasswordChange = (event) => {
    const newpasswordchange = event.target.value;
    setNewPassword(newpasswordchange);
  };

  const handlenewconfirmNewPasswordChange = (event) => {
    const newpasswordchange = event.target.value;
    setconfirmNewPassword(newpasswordchange);
  };

  
  


  const handleSaveClicksave = () => {

    setIsLoadingsubmit(true);

    const formData ={
        password:oldpassword,
        passwordnew:newpassword
    }


    if(newpassword === confirmNewPassword){


    const userid = JSON.parse(localStorage.getItem("adminuserdata"));
    axios
      .post(`${url}/users/changepassword`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userid.token}`,
        },
      })
      .then((response) => {
        setIsLoadingsubmit(false);
       toast.success("Password Updated Successfully", {
        position:"top-center",
      });

      

      })
      .catch((error) => {
        setIsLoadingsubmit(false);
        console.error("Error updating user data:", error);
        toast.error("Error Changing Password", {
            position: "top-right",
          });
      });

    }else{

        toast.error("Password Don't Match", {
            position: "top-right",
          });
    

    }

  };

  return (
    <div className="container md:w-[700px]  mx-auto md:p-4  p-2">
        <div className="w-full max-w-md mx-auto">
      {/* Tabs Header */}
      <div className="flex border-b border-gray-300">
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === 0
              ? 'border-b-2 border-[#D4F5F9] text-[#D4F5F9]'
              : 'text-white'
          }`}
          onClick={() => setActiveTab(0)}
        >
          Profile
        </button>
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === 1
              ? 'border-b-2 border-[#D4F5F9] text-[#D4F5F9]'
              : 'text-white'
          }`}
          onClick={() => setActiveTab(1)}
        >
          Change Password
        </button>
        
      </div>

      {/* Tabs Content */}
      <div className="p-4">
        {activeTab === 0 &&   <div className="space-y-4 px-4 mt-[50px] gap-4 grid">
     
     <h2 className="text-[#D4F5F9] font-[900] text-[25px]">
       {" "}
       Profile Information
     </h2>

     <ToastContainer />
     <label className="text-[#fff] font-[900] text-[17px]"> Fullname</label>
     <input
       type="text"
       name="name"
       value={fullname}
       onChange={handleFullnameChange}
       className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
     />
     <label className="text-[#fff] font-[900] text-[17px]"> Email</label>
     <input
       type="email"
       name="email"
       value={email}
       onChange={handleEmailChange}
       className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
       readOnly
     />

{
 isLoadingsubmit ? <Activityindicator/> :   <button
 onClick={handleSaveClick}
 className="w-full p-2 bg-[#D4F5F9] rounded text-black"
>
 Update Profile
</button>
}
   
   </div>}
        {activeTab === 1 &&  <div className="space-y-4 px-4 mt-[50px] gap-4 grid">
     
     <h2 className="text-[#D4F5F9] font-[900] text-[25px]">
       {" "}
       Change Password
     </h2>

     <ToastContainer />
     <label className="text-[#fff] font-[900] text-[17px]"> Enter Old Password</label>
     <input
       type="text"
       name="oldpassword"
       value={oldpassword}
       onChange={handleoldpasswordChange}
       className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
       required
     />
     <label className="text-[#fff] font-[900] text-[17px]"> Enter New Password</label>
     <input
       type="text"
       name="text"
       value={newpassword}
       onChange={handlenewpasswordChange}
       className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
     required
     />

  <label className="text-[#fff] font-[900] text-[17px]"> Confirm New Password</label>
     <input
       type="text"
       name="text"
       value={confirmNewPassword}
       onChange={handlenewconfirmNewPasswordChange}
       className="w-full p-2 text-white h-[60px] px-3 border rounded border-blue-200/30 bg-transparent"
  required
     />
{
 isLoadingsubmit ? <Activityindicator/> :   <button
 onClick={handleSaveClicksave}
 className="w-full p-2 bg-[#D4F5F9] rounded text-black"
>
Change Password
</button>
}
   
   </div>}

      </div>
    </div>
    </div>
  );
};

export default Profilepage;


