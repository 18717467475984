import React, { useState, useEffect } from "react";
import { url } from "../api/api";
import Dashboardsidenav from "../component/Dasboard/Dashboardsidenav";
import axios from "axios";
import Navbar from "../component/Dasboard/Navbar";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

const Viewwithdrawals = () => {
  const [balance, setBalance] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(balance?.map((transaction) => transaction.withdrawalamount)),
  ];

  const filteredTransactions = balance?.filter((transaction) => {
    const matchesSearchTerm = transaction.withdrawalamount
      .toString()
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filter === "All" || transaction.withdrawalamount === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const fetchBalances = () => {
    axios
      .get(`${url}/withdrawal/fetchalluserwithdrawal`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setBalance(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    // Fetch data from backend
    fetchBalances();
  }, []);

  const handleApproveClick = (transactionId) => {
    setSelectedTransactionId(transactionId);
    setShowPopup(true);
  };

  const handleConfirmApprove = () => {
    axios
      .get(`${url}/withdrawal/verifywithdrawal/${selectedTransactionId}`)
      .then((response) => {
        // Handle success response
        toast.success("Approval successful", {
          position: "top-center",
        });
        fetchBalances();
        setShowPopup(false);
        setSelectedTransactionId(null);
      })
      .catch((error) => {
        toast.error("Error approving Withdrawal", {
          position: "top-center",
        });
        console.error("Error approving the transaction:", error);
      });
  };

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-4 flex flex-col md:flex-row gap-6 mt-20">
   
        <div className="w-full">
          <ToastContainer />
          <div className="mb-4 flex flex-col sm:flex-row gap-2">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full sm:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full sm:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>


          
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full text-sm">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">TRANSACTION ID</th>
                  <th className="py-2 px-4 border-b">WITHDRAWAL AMOUNT</th>
                  <th className="py-2 px-4 border-b">STATUS</th>
                  <th className="py-2 px-4 border-b">OPTIONS</th>
                  <th className="py-2 px-4 border-b">DATE</th>
                </tr>
              </thead>
              <tbody className="bg-[#0A0B1A]">
                {currentTransactions?.map((balances) => (
                  <tr key={balances.withdrawaltransactionid} className="text-[#D4F5F9]">
                    <td className="py-2 px-4 border-b">{balances.withdrawaltransactionid}</td>
                    <td className="py-2 px-4 border-b">{balances.withdrawalamount}</td>
                    <td className="py-2 px-4 border-b">
                      {balances.status === 0 ? "PENDING" : "APPROVED"}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {balances.status === 0 ? (
                        <button
                          onClick={() => handleApproveClick(balances._id)}
                          className="text-white px-4 py-2 rounded-md border border-white bg-transparent hover:bg-white hover:text-black"
                        >
                          APPROVE
                        </button>
                      ) : (
                        <button
                          className="text-white px-4 py-2 rounded-md border border-white bg-blue-300 cursor-not-allowed"
                          disabled
                        >
                          APPROVED
                        </button>
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">{balances.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
            <h2 className="text-lg font-bold mb-4">Confirm Approval</h2>
            <p>Are you sure you want to approve this transaction?</p>
            <div className="mt-4 flex justify-end gap-4">
              <button
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmApprove}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Viewwithdrawals;
