import React from "react";
import Luco from "../../img/luco.png";
import Mike from "../../img/mike.png";

const Founders = () => {
  return (
    <div className="w-full bg-[#00001A] px-6 py-10 sm:px-10 sm:py-16 lg:px-20 lg:py-20">
      <div>
        <h2 className="text-blue-200 text-[32px] sm:text-[42px] lg:text-[52px] font-[700] font-[istok] text-center lg:text-left">
          Founders
        </h2>
        <div className="mt-8 lg:mt-4 grid gap-10 lg:flex lg:justify-between">
          <div className="flex flex-col items-center text-center lg:text-left">
            <img src={Luco} alt="luko"  />
            <p className="text-[18px] lg:text-[20px] font-[500] text-blue-200 mt-[20px] lg:mt-[30px]">
              Luca Benevolo
            </p>
          </div>
          <div className="lg:pl-20 text-blue-200">
            <p className="text-[16px] sm:text-[18px] font-[500]">
              Luca Benevolo is a serial entrepreneur with an impressive track
              record of successful start-ups including Kryll. He launched
              Emerald Capital in March 2019 and, within 180 days, grew Emerald
              Capital into the largest digital asset investment platform in the
              world by profit and investment volume. A pioneer within the
              blockchain industry, Luca Benevolo has built Emerald Capital into
              the leading blockchain ecosystem, comprised of Emerald Capital,
              Labs, Launchpad, Academy, Research, Trust Wallet, Charity, NFT,
              and more.
            </p>
          </div>
        </div>
        <div className="mt-16 grid gap-10 lg:flex lg:justify-between">
          <div className="lg:pr-20 text-blue-200">
            <p className="text-[16px] sm:text-[18px] font-[500] md:block hidden">
       Michael Kamerman is the visionary founder of Emerald, bringing a wealth of experience and a deep passion for investment to the company. With a career spanning over two decades in the financial industry, Michael has established himself as a leader known for his strategic insights, innovative thinking, and commitment to excellence.
            </p>
          </div>
          <div className="flex flex-col items-center text-center lg:text-left">
            <img src={Mike} alt="mike"  />
            <p className="text-[18px] lg:text-[20px] font-[500] text-blue-200 mt-[20px] lg:mt-[30px]">
              Michael Kamerman
            </p>
          </div>
          <div className="lg:pr-20 text-blue-200">
            <p className="text-[16px] sm:text-[18px] font-[500] md:hidden block">
            Michael Kamerman is the visionary founder of Emerald, bringing a wealth of experience and a deep passion for investment to the company. With a career spanning over two decades in the financial industry, Michael has established himself as a leader known for his strategic insights, innovative thinking, and commitment to excellence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
