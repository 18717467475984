import React, { useState } from "react";
import Navbar from "../component/Navbar";

import Webimagefooter from "../component/Login/Webimagefooter";
import axios from "axios";
import { url } from "../api/api";
import { useNavigate } from "react-router-dom";
import Activityindicator from "../Activityindicator";

const Verifyforgotcode = () => {
  const [formData, setFormData] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  });

  const [isLoading, setisLoading] = useState(false);

  const [error, seterrorStatus] = useState("");
  const navigate = useNavigate();
  const userid = localStorage.getItem("useridkey");

  console.log(userid);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const concatenateCodes = () => {
    const fullCode =
      formData.code1 + formData.code2 + formData.code3 + formData.code4;
    return fullCode;
  };

  const urll = `${url}/users/verifyforgortpasscode`;
  console.log(urll);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setisLoading(true);

    const fullCode = concatenateCodes();
    await axios
      .post(
        urll,
        { code: fullCode },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Process the successful response here
        setisLoading(false);

        navigate("/updatepass");
      })
      .catch((error) => {
        // Handle error responses here
        if (error.response) {
          setisLoading(false);
          if (error.response.status === 422) {
            seterrorStatus("Code does not exist");
          } else {
            seterrorStatus("Unknown error exist while submiting form");
          }

          console.error("Error:", error.message);
        } else {
          setisLoading(false);
          console.error("Error:", error.message);
        }
      });
  };

  return (
    <div className="w-full">
      <Navbar />

      <div className=" w-full h-[800px]  md:h-[650px] grid place-items-center bg-[#00001A] z-50">
        <div className=" bg-[#0096C7] text-white rounded-lg shadow-lg p-8 max-w-sm w-full">
          <div className="px-4 py-4">
            <h2 className="text-[28px] font-bold mb-6 text-center text-[#00001A] font-[istok]">
              Verify Your Email
            </h2>
            <p className=" text-[14px]  text-black font-[istok] mt-[-25px] text-center">
              Kindly enter the 4 digit code that was sent to email{" "}
              <font className=" text-red-500 font-extrabold">INBOX/SPAM</font>{" "}
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 mt-[30px]">
            {error && <h2 className="text-red-500"> {error}</h2>}
            <div className="flex gap-4">
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                id="code1"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px]"
                name="code1"
                value={formData.code1}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                id="code2"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px] "
                name="code2"
                value={formData.code2}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                id="code3"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px] "
                name="code3"
                value={formData.code3}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                id="code4"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px] "
                name="code4"
                value={formData.code4}
                onChange={handleChange}
                required
              />
            </div>

            <br />

            <div className=" mt-[146px]">
              {isLoading ? (
                <Activityindicator />
              ) : (
                <button
                  type="submit"
                  className="  font-[istok] w-full py-4 px-4 bg-white rounded-md  focus:outline-none focus:ring-2 focus:ring-black text-black"
                >
                  Verify
                </button>
              )}
            </div>

            {/* <p className="text-black font-[istok]">
              Didn’t get any code?{" "}
              <Link className="text-[#fff] font-[istok]"> Resend code</Link>
            </p> */}
          </form>
        </div>
      </div>

      <Webimagefooter />
    </div>
  );
};

export default Verifyforgotcode;
