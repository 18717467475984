import React from "react";
import Index from "../component/Privacy";
import Navbar from "../component/Navbar";
import Tabs from "../component/Privacy/Tabs";
import Webimageprivacy from "../component/Privacy/Webimageprivacy";

import Footer from "../component/Footer";

const Privacy = () => {
  return (
    <div className="bg-[#00001A] ">
      <Navbar />
      <Index />
      <div className=" md:h-[600px] bg-black w-full grid place-items-center">
        <Tabs />
      </div>
      <div className="md:pb-[150px]">
        <Webimageprivacy />
      </div>

   
      <Footer/>
    </div>
  );
};

export default Privacy;
