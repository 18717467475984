import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Lofo from "../img/bitcoin.png";
import ethimg from "../img/eth.png";
import bnbimg from "../img/bnb.png";
import xrpimg from "../img/xrp.png";

function Jumbotron() {

  const [cryptoRates, setCryptoRates] = useState({
    Bitcoin: { usd: null, image: Lofo }, // Add the image file names here
    Ethereum: { usd: null, image: ethimg },
    BnB: { usd: null, image: bnbimg },
    Ripple: { usd: null, image: xrpimg },
  });

  useEffect(() => {
    const fetchCryptoRates = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin,ripple&vs_currencies=usd"
        );
        if (response && response.data) {
          const updatedCryptoRates = {};
          Object.keys(response.data).forEach((crypto) => {
            const abbreviations = {
              bitcoin: "Bitcoin",
              ethereum: "Ethereum",
              binancecoin: "BnB",
              ripple: "Ripple",
            };
            const abbreviation = abbreviations[crypto];
            if (abbreviation) {
              updatedCryptoRates[abbreviation] = {
                usd: response.data[crypto].usd,
                image: cryptoRates[abbreviation].image,
              };
            }
          });
          setCryptoRates(updatedCryptoRates);
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Error fetching cryptocurrency rates:", error);
      }
    };

    // Fetch cryptocurrency rates on component mount
    fetchCryptoRates();

    // Fetch cryptocurrency rates every 60 seconds (optional)
    const intervalId = setInterval(fetchCryptoRates, 60000);

    // Clean up interval
    return () => clearInterval(intervalId);
  }, []);




  return (
    <div className="min-h-screen bg-[#00001A] px-4 md:px-20 flex flex-col md:flex-row justify-center items-center">
      <div className="mt-[-100px] md:mt-[-200px] md:mr-[150px] text-center md:text-left">
        <p className="text-[32px] md:text-[42px]">
          <span className="text-[#D4F5F9] font-istok-web font-[700]">Buy</span>{" "}
          <span className="text-[#fff] font-istok-web font-[700]">&</span>{" "}
          <span className="text-[#F2C493] font-istok-web font-[700]">Invest</span>
        </p>
        <p className="text-[#fff] font-istok-web text-[32px] md:text-[42px] font-[700]">
          Crypto Tokens
        </p>
        <p className="text-[#fff] font-istok-web text-[32px] md:text-[42px] font-[700]">
          Easily
        </p>
        <p className="text-[#fff] font-istok-web text-[12px] md:text-[14px] font-[500] mt-4 md:mt-2">
          Emerald Capital is a crypto investment platform that enables both{" "}
          <br className="hidden md:block" /> Crypto newbies and pros to invest
          and, maintain and grow a
          <br className="hidden md:block" /> portfolio of digital assets.
        </p>
        <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-[10px] rounded-[15px] place-items-center bg-[#0096C7] text-[16px] md:text-[18px] font-[500] text-[#00001A] px-4 py-4 mx-auto md:mx-0">
          <Link to="/signup" className="font-istok-web">Get Started</Link>
        </div>
      </div>

      <div className="mt-8 md:mt-[-200px] grid place-items-center w-full md:w-[400px] h-auto md:h-[400px] bg-gradient-to-br from-[#0096C7] via-[#E8EEF1] to-[#F2C493] rounded-lg shadow-lg p-6">
        {Object.keys(cryptoRates).map(
          (crypto) =>
            // Check if the rate data is available
            cryptoRates[crypto] &&
            cryptoRates[crypto].usd && (
              <div
                key={crypto}
                className="flex items-center justify-between w-full md:w-72 py-2"
              >
                <div className="w-10 md:w-20">
                  {cryptoRates[crypto].image && (
                    <img
                      src={cryptoRates[crypto].image}
                      alt={crypto}
                      className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] mr-2"
                    />
                  )}
                </div>
                <div className="w-20 md:w-30">
                  <p className="text-[12px] md:text-[14px] text-left font-[istok] font-[900] text-[#000]">
                    {crypto}
                  </p>
                </div>
                <div className="w-30 md:w-40">
                  <p className="text-[14px] md:text-[16px] font-[istok] font-[500] text-[#4AE115]">
                    ${cryptoRates[crypto].usd}
                  </p>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Jumbotron;
