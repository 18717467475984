import React, { useState, useEffect } from "react";
import { url } from "../api/api";
import axios from "axios";
import Navbar from "../component/Dasboard/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Viewinvestmentdepositmethod = () => {
  const [investmentMethod, setInvestmentmethod] = useState([]);
  const [newinvestmentmethodplanname, setNewinvestmentmethodplanname] =
    useState("");
  const [newinvestmentmethodfrom, setNewinvestmentmethodfrom] = useState("");
  const [newinvestmentmethodto, setNewinvestmentmethodto] = useState("");
  const [newinvestmentmethodduration, setNewinvestmentmethodduration] =
    useState("");
  const [newinvestmentmethodroi, setNewinvestmentmethodroi] = useState("");

  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [newdepositmethod, setNewDepositmethod] = useState("");
  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentdeleteMethod, setCurrentdeleteMethod] = useState(null);
  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(investmentMethod?.map((transaction) => transaction.planname)),
  ];

  const filteredTransactions = investmentMethod?.filter((transaction) => {
    const matchesSearchTerm = transaction.planname
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter = filter === "All" || transaction.planname === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentWithdrawalmethod = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const fetchAllInvestmentMethods = () => {
    axios
      .get(`${url}/investment/fetchallinvestmentmethod`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setInvestmentmethod(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching Deposit methods:", error);
      });
  };

  useEffect(() => {
    fetchAllInvestmentMethods();
  }, []);

  const handleAddNewMethod = () => {
    setIsAddPopupOpen(true);
  };

  const handleEditMethod = (method) => {
    setCurrentMethod(method);
    setNewinvestmentmethodplanname(method.planname);
    setNewinvestmentmethodfrom(method.from);
    setNewinvestmentmethodto(method.to);
    setNewinvestmentmethodduration(method.duration);
    setNewinvestmentmethodroi(method.roi);
    setIsEditPopupOpen(true);
  };

  const handleDeleteMethod = (method) => {
    setCurrentdeleteMethod(method);
    console.log(method);
    setIsDeletePopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsAddPopupOpen(false);
    setIsEditPopupOpen(false);
    setIsDeletePopupOpen(false);
    setNewDepositmethod("");
    setCurrentMethod(null);
  };

  const handleAddFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${url}/investment/addinvestmentmethod`,
        {
          planname: newinvestmentmethodplanname,
          from: newinvestmentmethodfrom,
          to: newinvestmentmethodto,
          duration: newinvestmentmethodduration,
          roi: newinvestmentmethodroi,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Added new investment method uccessfully!");
        fetchAllInvestmentMethods();
        handleClosePopup();
      })
      .catch((error) => {
        toast.error("Error adding investment method!");
        console.error("Error adding investment method:", error);
      });
  };

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    axios
      .patch(
        `${url}/investment/editsingleinvestmentmethod/${currentMethod._id}`,
        {
          planname: newinvestmentmethodplanname,
          from: newinvestmentmethodfrom,
          to: newinvestmentmethodto,
          duration: newinvestmentmethodduration,
          roi: newinvestmentmethodroi,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Investment method updated successfully!");
        fetchAllInvestmentMethods();
        handleClosePopup();
      })
      .catch((error) => {
        toast.error("Error updating Investment method!");
        console.error("Error updating Investment method:", error);
      });
  };

  const handleDeleteConfirm = () => {

    console.log(currentdeleteMethod._id)
    axios
      .delete(`${url}/investment/deleteinvestmentmethod/${currentdeleteMethod._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success("Investment method deleted successfully!");
        fetchAllInvestmentMethods();
        handleClosePopup();
      })
      .catch((error) => {
        toast.error("Error deleting payment method!");
        console.error("Error deleting payment method:", error);
      });
  };

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-6 flex flex-col md:flex-row gap-6 w-full mt-20">
        <div className="mb-4 w-full">
          <ToastContainer />
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <button
              className="px-6 py-2 rounded border border-white text-white"
              onClick={handleAddNewMethod}
            >
              Add New Investment Method
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full mt-4 text-center">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">PLAN NAME</th>
                  <th className="py-2 px-4 border-b"> FROM</th>
                  <th className="py-2 px-4 border-b">TO</th>
                  <th className="py-2 px-4 border-b">DURATION</th>
                  <th className="py-2 px-4 border-b">ROI</th>
                  <th className="py-2 px-4 border-b">OPTIONS</th>
                </tr>
              </thead>
              <tbody>
                {currentWithdrawalmethod?.map((investmentmethods) => (
                  <tr key={investmentmethods._id} className="px-10 py-10">
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {investmentmethods.planname}
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {investmentmethods.from}
                    </td>

                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {investmentmethods.to}
                    </td>

                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {investmentmethods.duration} DAYS
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {investmentmethods.roi}%
                    </td>

                    <td className="py-2 px-4 flex gap-6 text-[#fff]">
                      <button
                        className="px-6 py-2 rounded border border-white text-white"
                        onClick={() => handleEditMethod(investmentmethods)}
                      >
                        EDIT
                      </button>
                      <button
                        className="px-6 py-2 rounded border border-white text-white"
                        onClick={() => handleDeleteMethod(investmentmethods)}
                      >
                        DELETE
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Add Popup Form */}
      {isAddPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-11/12 md:w-1/3">
            <h2 className="text-2xl mb-4">Add New Investment Method</h2>
            <form onSubmit={handleAddFormSubmit}>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter plan name"
                value={newinvestmentmethodplanname}
                onChange={(e) => setNewinvestmentmethodplanname(e.target.value)}
                required
              />

              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter from method"
                value={newinvestmentmethodfrom}
                onChange={(e) => setNewinvestmentmethodfrom(e.target.value)}
                required
              />

              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter to method"
                value={newinvestmentmethodto}
                onChange={(e) => setNewinvestmentmethodto(e.target.value)}
                required
              />
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter return on investment"
                value={newinvestmentmethodroi}
                onChange={(e) => setNewinvestmentmethodroi(e.target.value)}
                required
              />
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter duration"
                value={newinvestmentmethodduration}
                onChange={(e) => setNewinvestmentmethodduration(e.target.value)}
                required
              />
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Add Method
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Popup Form */}
      {isEditPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-11/12 md:w-1/3">
            <h2 className="text-2xl mb-4">Edit Investment Method</h2>
            <form onSubmit={handleEditFormSubmit}>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter plan name"
                value={newinvestmentmethodplanname}
                onChange={(e) => setNewinvestmentmethodplanname(e.target.value)}
                required
              />

              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter from method"
                value={newinvestmentmethodfrom}
                onChange={(e) => setNewinvestmentmethodfrom(e.target.value)}
                required
              />

              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter to method"
                value={newinvestmentmethodto}
                onChange={(e) => setNewinvestmentmethodto(e.target.value)}
                required
              />
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter return on investment"
                value={newinvestmentmethodroi}
                onChange={(e) => setNewinvestmentmethodroi(e.target.value)}
                required
              />
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter duration"
                value={newinvestmentmethodduration}
                onChange={(e) => setNewinvestmentmethodduration(e.target.value)}
                required
              />
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Update Method
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {isDeletePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-11/12 md:w-1/3">
            <h2 className="text-2xl mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the withdrawal method "
              {currentdeleteMethod?.planname}"?
            </p>
            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-500 text-white rounded"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Viewinvestmentdepositmethod;
