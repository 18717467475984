// src/Tabs.js
import React, { useState } from "react";
import Resume from "../../img/resume.png";
import { Link } from "react-router-dom";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    "Using Your Data",
    "Data Retention",
    "Sharing third party data",
    "Cookies Policy",
  ];

  return (
    <div className="w-full md:w-[700px] md:h-[400px] bg-[#1E1E1E] rounded-md grid px-10 py-10  z-40 ">
      <div className=" h-[30px] grid pb-20">
        <h2 className=" text-blue-200 text-center text-[24px]">
          How Emerald Capital Uses Your Data
        </h2>
      </div>

      <div className="flex">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`flex-1 py-2 text-center text-[12px] md:text-[14px] ${
              activeTab === index
                ? " text-blue-300 md:text-[14px]"
                : "text-white md:text-[14px]"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="p-4">
        {activeTab === 0 && (
          <div className=" flex justify-between">
            <div className="text-white">
              Users are at the heart of everything we do. From the beginning,
              we’ve made user protection our top priority. That is why we’ve
              embedded state-of-the-art security measures and strict data
              privacy controls across the entire Emerald Invest ecosystem.
              <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-8 rounded-[15px] place-items-center bg-[#0096C7] text-base md:text-lg font-medium text-[#00001A] px-4 py-4">
                <Link to="#" className="font-istok-web md:text-[14px] text-[12px]">
                  See full Privacy Policy
                </Link>
              </div>
            </div>

            <div>
              <img src={Resume} alt="" />
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div className=" flex justify-between">
            <div className="text-white">
              Data are at the heart of everything we do. From the beginning,
              we’ve made user protection our top priority. That is why we’ve
              embedded state-of-the-art security measures and strict data
              privacy controls across the entire Emerald Invest ecosystem.
              <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-8 rounded-[15px] place-items-center bg-[#0096C7] text-base md:text-lg font-medium text-[#00001A] px-4 py-4">
                <Link to="#" className="font-istok-web md:text-[14px] text-[12px]">
                  See full Privacy Policy 
                </Link>
              </div>
            </div>

            <div>
              <img src={Resume} alt="" />
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div className=" flex justify-between">
            <div className="text-white">
              Sharing are at the heart of everything we do. From the beginning,
              we’ve made user protection our top priority. That is why we’ve
              embedded state-of-the-art security measures and strict data
              privacy controls across the entire Emerald Invest ecosystem.
              <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-8 rounded-[15px] place-items-center bg-[#0096C7] text-base md:text-lg font-medium text-[#00001A] px-4 py-4">
                <Link to="#" className="font-istok-web md:text-[14px] text-[12px]">
                  See full Privacy Policy
                </Link>
              </div>
            </div>

            <div>
              <img src={Resume} alt="" />
            </div>
          </div>
        )}
        {activeTab === 3 && (
          <div className=" flex justify-between">
            <div className="text-white">
              Cookies are at the heart of everything we do. From the beginning,
              we’ve made user protection our top priority. That is why we’ve
              embedded state-of-the-art security measures and strict data
              privacy controls across the entire Emerald Invest ecosystem.
              <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-8 rounded-[15px] place-items-center bg-[#0096C7] text-base md:text-lg font-medium text-[#00001A] px-4 py-4">
                <Link
                  to="#"
                  className="font-istok-web md:text-[14px] text-[12px]"
                >
                  See full Privacy Policy 
                </Link>
              </div>
            </div>

            <div>
              <img src={Resume} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
