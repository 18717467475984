import React from "react";

const Outforte = () => {
  return (
    <div className="w-full h-auto px-6 py-10 md:px-24 md:py-20">
      <h2 className="text-blue-200 font-bold text-2xl md:text-4xl leading-tight">
        Our Forte <br />
      </h2>
      <p className="text-[#D4F5F9] text-sm md:text-base mt-4 md:mt-2">
      We uphold the highest standards of integrity, transparency, and accountability in all our dealings, fostering trust and long-lasting relationships with our clients.
      </p>
    </div>
  );
};

export default Outforte;
