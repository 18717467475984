import React, { useState, useEffect } from "react";
import { url } from "../api/api";
import axios from "axios";
import Navbar from "../component/Dasboard/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Viewdepositmethods = () => {
  const [depositmethod, setDepositmethod] = useState([]);
  const [newdepositAddress, setNewDepositaddress] = useState("");

  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [newdepositmethod, setNewDepositmethod] = useState("");
  const [currentMethod, setCurrentMethod] = useState(null);

  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(depositmethod?.map((transaction) => transaction.paymentname)),
  ];

  const filteredTransactions = depositmethod?.filter((transaction) => {
    const matchesSearchTerm = transaction.paymentname
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filter === "All" || transaction.paymentname === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentWithdrawalmethod = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const fetchAllDepositMethods = () => {
    axios
      .get(`${url}/funding/fetchpaymentmethod`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setDepositmethod(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching Deposit methods:", error);
      });
  };

  useEffect(() => {
    fetchAllDepositMethods();
  }, []);

  const handleAddNewMethod = () => {
    setIsAddPopupOpen(true);
  };

  const handleEditMethod = (method) => {
    setCurrentMethod(method);
    setNewDepositmethod(method.paymentname);
    setNewDepositaddress(method.paymentaddress)
    setIsEditPopupOpen(true);
  };

  const handleDeleteMethod = (method) => {
    setCurrentMethod(method);
    setIsDeletePopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsAddPopupOpen(false);
    setIsEditPopupOpen(false);
    setIsDeletePopupOpen(false);
    setNewDepositmethod("");
    setCurrentMethod(null);
  };

  const handleAddFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${url}/funding/addpaymentmethod`,
        { paymentname: newdepositmethod, paymentaddress: newdepositAddress },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Added new Deposid method added successfully!");
        fetchAllDepositMethods();
        handleClosePopup();
      })
      .catch((error) => {
        toast.error("Error adding deposit method!");
        console.error("Error adding deposit method:", error);
      });
  };

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    axios
      .patch(
        `${url}/funding/editpaymentmethod/${currentMethod._id}`,
        { paymentname: newdepositmethod, paymentaddress: newdepositAddress },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Deposit method updated successfully!");
        fetchAllDepositMethods();
        handleClosePopup();
      })
      .catch((error) => {
        toast.error("Error updating Deposit method!");
        console.error("Error updating Deposit method:", error);
      });
  };

  const handleDeleteConfirm = () => {
    axios
      .delete(`${url}/funding/deletepaymentmethod/${currentMethod._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success("Payment method deleted successfully!");
        fetchAllDepositMethods();
        handleClosePopup();
      })
      .catch((error) => {
        toast.error("Error deleting payment method!");
        console.error("Error deleting payment method:", error);
      });
  };

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-6 flex flex-col md:flex-row gap-6 w-full mt-20">
        <div className="mb-4 w-full">
          <ToastContainer />
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <button
              className="px-6 py-2 rounded border border-white text-white"
              onClick={handleAddNewMethod}
            >
              Add New Deposit Method
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full mt-4 text-center">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">Deposit Method</th>
                  <th className="py-2 px-4 border-b">Deposit Address</th>
                  <th className="py-2 px-4 border-b">Date</th>
                  <th className="py-2 px-4 border-b">OPTIONS</th>
                </tr>
              </thead>
              <tbody>
                {currentWithdrawalmethod?.map((depositmethods) => (
                  <tr key={depositmethods._id} className="px-10 py-10">
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {depositmethods.paymentname}
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {depositmethods.paymentaddress}
                    </td>
                    <td className="py-2 px-4 text-[#fff]">
                      {new Date(depositmethods.createdAt).toLocaleDateString()}
                    </td>

                    <td className="py-2 px-4 flex gap-6 text-[#fff]">
                      <button
                        className="px-6 py-2 rounded border border-white text-white"
                        onClick={() => handleEditMethod(depositmethods)}
                      >
                        EDIT
                      </button>
                      <button
                        className="px-6 py-2 rounded border border-white text-white"
                        onClick={() => handleDeleteMethod(depositmethods)}
                      >
                        DELETE
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Add Popup Form */}
      {isAddPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-11/12 md:w-1/3">
            <h2 className="text-2xl mb-4">Add New Deposit Method</h2>
            <form onSubmit={handleAddFormSubmit}>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter deposit method"
                value={newdepositmethod}
                onChange={(e) => setNewDepositmethod(e.target.value)}
                required
              />

              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter deposit address"
                value={newdepositAddress}
                onChange={(e) => setNewDepositaddress(e.target.value)}
                required
              />
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Add Method
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Popup Form */}
      {isEditPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-11/12 md:w-1/3">
            <h2 className="text-2xl mb-4">Edit Deposit Method</h2>
            <form onSubmit={handleEditFormSubmit}>
            <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter deposit method"
                value={newdepositmethod}
                onChange={(e) => setNewDepositmethod(e.target.value)}
                required
              />

              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                placeholder="Enter deposit address"
                value={newdepositAddress}
                onChange={(e) => setNewDepositaddress(e.target.value)}
                required
              />

              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Update Method
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {isDeletePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-11/12 md:w-1/3">
            <h2 className="text-2xl mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the withdrawal method "
              {currentMethod?.paymentname}"?
            </p>
            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-gray-500 text-white rounded"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Viewdepositmethods;
