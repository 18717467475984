import React, { useState, useEffect } from "react";
import { url } from "../api/api";
import Dashboardsidenav from "../component/Dasboard/Dashboardsidenav";
import axios from "axios";
import Navbar from "../component/Dasboard/Navbar";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Viewusers = () => {
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(users?.map((transaction) => transaction.fullname)),
  ];

  const filteredTransactions = users?.filter((transaction) => {
    const matchesSearchTerm = transaction.fullname
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter = filter === "All" || transaction.fullname === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const viewallusers = () => {
    axios
      .get(`${url}/users/viewallusers`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    viewallusers();
  }, []);

  const handleViewBalanceClick = (userId) => {
    navigate(`/user/${userId}/transactions`);
  };

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-6 flex flex-col md:flex-row gap-6 w-full mt-20">
    

        <div className="mb-4 w-full">
          <ToastContainer />
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full mt-4 text-center">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">FULLNAME</th>
                  <th className="py-2 px-4 border-b">EMAIL</th>
                  <th className="py-2 px-4 border-b">USER STATUS</th>
                  <th className="py-2 px-4 border-b">Options</th>
                  <th className="py-2 px-4 border-b">Date</th>
                </tr>
              </thead>
              <tbody>
                {currentTransactions?.map((user) => (
                  <tr key={user._id} className="px-10 py-10">
                    <td className="py-2 px-4 text-[#D4F5F9]">{user.fullname}</td>
                    <td className="py-2 px-4 text-[#D4F5F9]">{user.email}</td>
                    <td className="py-2 px-4 text-[#fff]">
                      {user.status === 0 ? "UNVERIFIED" : "VERIFIED"}
                    </td>
                    <td className="py-2 px-4 text-[#fff]">
                      <button
                        onClick={() => handleViewBalanceClick(user._id)}
                        className="px-4 py-2 rounded border border-white text-white"
                      >
                        VIEW BALANCE
                      </button>
                    </td>
                    <td className="py-2 px-4 text-[#fff]">{user.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewusers;
