import React from "react";
import Navbar from "../component/Navbar";
import { HiCheckCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

import Webimagefooter from "../component/Login/Webimagefooter";

const Emailsuccess = () => {
  return (
    <div className="w-full">
      <Navbar />

      <div className=" w-full h-[800px]  md:h-[650px] grid place-items-center bg-[#00001A] z-50">
        <div className=" bg-[#0096C7] text-white rounded-lg shadow-lg p-8 max-w-sm w-full">
          <div className="px-4 py-4">
            <HiCheckCircle className="text-[#00001A]  text-6xl mb-4" />
            <h1 className="text-3xl font-bold mb-4">
              Email Verification Successful
            </h1>
            <p className="text-lg mb-8">
              Your account has been successfully verified!
            </p>

            <Link
              to="/"
              className="bg-[#213f7a] rounded-md px-6 py-3 text-white font-semibold"
            >
              Continue
            </Link>
          </div>
        </div>
      </div>

      <Webimagefooter />
    </div>
  );
};

export default Emailsuccess;
