import React, { useState, useEffect } from "react";
import { url } from "../api/api";
import Dashboardsidenav from "../component/Dasboard/Dashboardsidenav";
import axios from "axios";
import Navbar from "../component/Dasboard/Navbar";

import { ToastContainer } from "react-toastify";

const Viewinvestment = () => {
  const [investment, setInvestment] = useState([]);

  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(investment?.map((transaction) => transaction.investinvestmemntamount)),
  ];

  const filteredTransactions = investment?.filter((transaction) => {
    const matchesSearchTerm = transaction.investinvestmemntamount;
    const matchesFilter =
      filter === "All" || transaction.investinvestmemntamount === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const fetchinvestments = () => {
    axios
      .get(`${url}/investment/fetchallinvestment`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setInvestment(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    fetchinvestments();
  }, []);

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-6 flex flex-col md:flex-row gap-6 w-full mt-20">


        <div className="mb-4 w-full">
          <ToastContainer />
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full mt-4 text-center">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">Investment Amount</th>
                  <th className="py-2 px-4 border-b">Duration</th>
                  <th className="py-2 px-4 border-b">Roi</th>
                  <th className="py-2 px-4 border-b">Profit</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Date</th>
                </tr>
              </thead>
              <tbody>
                {currentTransactions?.map((balances) => (
                  <tr key={balances.investmentmethodid} className="px-10 py-10">
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {balances.investinvestmemntamount}
                    </td>
                    <td className="py-2 px-4 text-[#fff]">
                      {balances.duration} Days
                    </td>
                    <td className="py-2 px-4 text-[#fff]">{balances.roi}</td>
                    <td className="py-2 px-4 text-[#fff]">{balances.profit}</td>
                    <td className="py-2 px-4 text-[#fff]">
                      {balances.status === 0 ? "ONGOING" : "COMPLETED"}
                    </td>
                    <td className="py-2 px-4 text-[#fff]">{balances.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-white">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewinvestment;
