import React from "react";
import Putf from "../../img/putf.png";
import { Link } from "react-router-dom";

const Putfirst = () => {
  return (
    <div className=" w-full h-auto px-6 py-10 md:px-24 md:py-20 grid md:grid-cols-2 gap-12 md:mt-[-80px] grid-cols-1 bg-[#1E1E1E]">
      <div>
        <img src={Putf} alt="" />
      </div>
      <div>
        <h2 className=" text-[40px] font-[700] font-[istok]  text-blue-200">
          {" "}
          Putting Our Users First
        </h2>
        <p className=" text-blue-200   text-[18px] font-[istok]">
          Users are at the heart of everything we do. From the beginning, we’ve
          made user protection our top priority. That is why we’ve embedded
          state-of-the-art security measures and strict data privacy controls
          across the entire Emerald Invest ecosystem.
        </p>
        <div className="w-40 md:w-60 grid font-istok-web h-[50px] mt-4 md:mt-8 rounded-[15px] place-items-center bg-[#0096C7] text-base md:text-lg font-medium text-[#00001A] px-4 py-4">
          <Link to="/privacy" className="font-istok-web">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Putfirst;
